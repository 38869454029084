import { Component, Input, OnInit } from '@angular/core';
import { MasterDataService } from 'src/app/services/master-data.service';
import { Category } from 'src/app/util/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-category-thumbnail',
  templateUrl: './category-thumbnail.component.html',
  styleUrls: ['./category-thumbnail.component.scss']
})
export class CategoryThumbnailComponent implements OnInit {
  @Input() private category: Category;
  @Input() private displayGradient: boolean;
  @Input() public opacity: number;

  constructor(private _masterData: MasterDataService) { }

  ngOnInit(): void {
    this.category = this.category || {};
  }

  public getImgSrc() {
    return this.category ? this._masterData.getPictureUrlByCategory(this.category) : '';
  }

  public getTitleImgSrc() {
    return this.category ? this._masterData.getPictureUrlByPictureName(this.category.picture_title) : '';
  }  

  public isGradiantDisplay() {
    return this.displayGradient === true ? true : false;
  }

 }
