import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { I18n } from 'src/app/services/i18n.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import {  Category, MasterData } from 'src/app/util/interfaces';

@Component({
  selector: 'app-categories-view',
  templateUrl: './categories-view.component.html',
  styleUrls: ['./categories-view.component.scss'],
  // animations: [slideInAnimation],
  // host: { '[@slideInAnimation]': '' }
})
export class CategoriesViewComponent implements OnInit, OnDestroy {

  public categories: Category[];
  private _subscription: Subscription;

  constructor(private masterDataService: MasterDataService,
    public i18n: I18n
  ) { }

  ngOnInit(): void {
   this._subscription = this.masterDataService.musicDataSubject.subscribe(
      (data: MasterData) => {
        this.categories = data.categories;
        this.categories.sort(this._sortBySortNumber);
      });

    this.masterDataService.emitMasterDataSubject();
  }

 private _sortBySortNumber(a: any, b: any) {
    return a.sort - b.sort;
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

}
