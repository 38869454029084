import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18n } from 'src/app/services/i18n.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { Category, Music } from 'src/app/util/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-music-details-view',
  templateUrl: './music-details-view.component.html',
  styleUrls: ['./music-details-view.component.scss']
})
export class MusicDetailsViewComponent implements OnInit {

  public musicId: number;
  public music: Music;
  public category: Category;
  public musicPictureUrl: string;

  constructor(private _route: ActivatedRoute, private _masterData: MasterDataService, public i18n: I18n) { }

  ngOnInit(): void {
    this._route.params.subscribe((params => {
      this.musicId = +params['id'];
      this.music = this._masterData.getMusicById(this.musicId);
      if (this.music.data.picture) {
        this.musicPictureUrl = this._masterData.getPictureUrlByMusic(this.music);
      }
      this.category = this._masterData.getCategoryById(this.music.categoryId);
    }));
  }
}
