import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentType } from '@angular/cdk/portal';
import { SettingsDialogComponent } from '../fragments/settings-dialog/settings-dialog.component';
import { PrivateDataInfoDialogContentComponent } from '../fragments/private-data-info-dialog-content/private-data-info-dialog-content.component';
import { LanguageDialogComponent } from '../fragments/language-dialog/language-dialog.component';


@Injectable()
export class PopUpService {
    dialogData: any;
    dialogRef: MatDialogRef<any, any>;
    dialogInstance: MatDialogRef<any, any>["componentInstance"]; // type of component insance of the current matdialog
    settingDialog: any;   

    constructor(private matDialog: MatDialog, private snackBar: MatSnackBar) {

    }

    public showSnackBarTop(sText: string) {
        this.snackBar.open(sText, "", {
            duration: 3000,
            verticalPosition: 'top'
        });
    }

    public showSnackBarBottom(sText: string) {
        this.snackBar.open(sText, "", {
            duration: 3000,
            verticalPosition: 'bottom'
        });
    }

    public open = this.showSnackBarTop;

    public showError(sText: string) {
        this.snackBar.open(sText, "", {
            duration: 3000,
            verticalPosition: 'bottom',

        });
    }

    public displayDialog(sDialogComponent: string, dialogData?: any): MatDialogRef<any, any>["componentInstance"] {
       let isSettingsDialog = false
        if (dialogData) {
            this.dialogData = dialogData;
        }
        let dialogComponent: ComponentType<any>;
        switch (sDialogComponent) {
            case "SettingsDialogComponent":
                dialogComponent = SettingsDialogComponent;    
                isSettingsDialog = true
                break;
            case "PrivateDataInfoDialogContentComponent":
                dialogComponent = PrivateDataInfoDialogContentComponent;               
                break;
            case "LanguageDialogComponent":
                dialogComponent = LanguageDialogComponent;
                break;
        }

        // open the popup
        this.dialogRef = this.matDialog.open(dialogComponent, {
            data: this.dialogData
        });

        if (isSettingsDialog) {
            this.settingDialog = this.dialogRef.componentInstance          
        }
        
        // set and return the instance of the popup component
        this.dialogInstance = this.dialogRef.componentInstance;
        return this.dialogInstance;
    }

    public updateDialogData(oDialogData: any) {
        if (this.settingDialog) {
            this.settingDialog.data = oDialogData;
        }

    }

}