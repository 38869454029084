<app-toolbar></app-toolbar>
<mat-card>
    <mat-card-title>Small Eyes</mat-card-title>
    <mat-card-subtitle>Beat Maker</mat-card-subtitle>
    <mat-card-content>
        <div class="mainDiv">
            <p>{{i18n.get('oderViewPart1')}} {{orderParams.article}} - {{orderParams.category}}.</p>
            <p>{{i18n.get('price')}} : {{orderParams.price}}€ TTC.</p>
            <p class="">{{i18n.get('oderViewPart2')}}
                <a class="app-accent" routerLink="/contact">contact@smalleyesrecords.com</a>
            </p>
            <p>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{i18n.get('TOSPanelTitle')}}
                        </mat-panel-title>

                    </mat-expansion-panel-header>
                    <a class="app-accent" target="_blank" href="./../../../assets/CGV.pdf">{{i18n.get('downloadTOS')}}</a>
                </mat-expansion-panel>
            </p>

            <section>
                <mat-checkbox [(ngModel)]="displayPaiement">{{i18n.get('readAndAgreeTOS')}}</mat-checkbox>
            </section>

            <p *ngIf="displayPaiement">{{i18n.get('oderViewPart3')}}</p>
        </div>
        <app-paypal [orderParams]="orderParams" *ngIf="displayPaiement"></app-paypal>
        <!-- <app-web3 [orderParams]="orderParams" *ngIf="displayPaiement"></app-web3> -->
    </mat-card-content>
</mat-card>