import { Component, OnDestroy, OnInit } from '@angular/core';
import { AudioPlayerService } from '../../services/audioPlayer.service';
import { MasterDataService } from '../../services/master-data.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { I18n } from 'src/app/services/i18n.service';
import { Subscription } from 'rxjs';
import { Music } from 'src/app/util/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-play-list',
  templateUrl: './play-list.component.html',
  styleUrls: ['./play-list.component.scss']
})
export class PlayListComponent implements OnInit, OnDestroy {

  constructor(private audioPlayerService: AudioPlayerService,
    private masterDataService: MasterDataService,
    private userService: UserService,
    private router: Router,
    public i18n: I18n) {
  }

  public playList: any[];
  private _subscription = new Subscription();

  ngOnInit(): void {
    this._subscription.add(
      this.masterDataService.playListSubject.subscribe(
        (playList: any[]) => {
          this.playList = playList;
        })
    )
    this.masterDataService.initFavorites();
  }

  public removeItemFromPlayList(music: Music) {
    this.masterDataService.deleteMusicfromPlaylist(music);
  }

  public playIt(musicItem: Music) {
    this.audioPlayerService.playIt(musicItem, "favorite");
  }

  public isActive(musicItem: Music) {
    if (musicItem.id == this.audioPlayerService.getState().music.id) {
      return true;
    }
  }

  public order(musicItem: Music) {
    this.router.navigate(['order/' + musicItem.id]);
  }

  public clearPlayList() {
    this.masterDataService.clearFavorites()
  }

  public onClickReturn() {
    window.history.back();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
