import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/services/i18n.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-who-are-we-view',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  seLogoSrc = `${environment.ASSETS}/images/${environment.imagesFileName.logoSEGreen}`
  xkLogoSrc = `${environment.ASSETS}/images/${environment.imagesFileName.logoXK}`
  constructor(public i18n: I18n) {

   }

  ngOnInit(): void {
  }

}
