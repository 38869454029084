import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18n } from 'src/app/services/i18n.service';

@Component({
  selector: 'app-thanks-view',
  templateUrl: './thanks-view.component.html',
  styleUrls: ['./thanks-view.component.scss']
})
export class ThanksViewComponent implements OnInit {

  /**
   * Paypal order id
   */
  public orderId: string

  /**
   * Transaction hash (ETH)
   */
  public txHash: string

  constructor(public i18n: I18n, private _route: ActivatedRoute) { }

  ngOnInit(): void {
    this.orderId = this._route.snapshot.params['id'];
    this.txHash = this._route.snapshot.params['tx'];
  }

}
