import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/services/i18n.service';

@Component({
  selector: 'app-copyright-view',
  templateUrl: './copyright-view.component.html',
  styleUrls: ['./copyright-view.component.scss']
})
export class CopyrightViewComponent implements OnInit {

  constructor(public i18n : I18n) { }

  ngOnInit(): void {
  }

}
