<h2 mat-dialog-title>{{i18n.get('TOUPanelTitle')}}</h2>
<mat-dialog-content style="max-width: 1200px;">
    <!-- <mat-expansion-panel style="max-width : 300px;">
        <mat-expansion-panel-header>
            <mat-panel-title style="font-size: 12px;">
                {{i18n.get('read')}}
            </mat-panel-title>
        </mat-expansion-panel-header> -->
        <p>{{i18n.get('TOUPart1')}}<br />
            {{i18n.get('TOUPart2')}}<br />
            {{i18n.get('TOUPart3')}}<br />
            {{i18n.get('TOUPart4')}}
        </p>
    <!-- </mat-expansion-panel> -->
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close (click)="onAcceptPolicy()">{{i18n.get('agree')}}</button>
</mat-dialog-actions>