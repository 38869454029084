<div>

  <mat-toolbar>
    <button mat-icon-button (click)="onClickReturn()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <span style="flex: 1 1 auto"></span>
    <span>{{categoryName}}</span>
    <span style="flex: 1 1 auto"></span>
    <span>
      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="addFolderToPlayList()">
          <mat-icon>favorite_border</mat-icon>
          <span>{{i18n.get('addAllToFavorites')}}</span>
        </button>
      </mat-menu>
      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon class="white-icon svg mat-icon35">add</mat-icon>
      </button>
    </span>
  </mat-toolbar>

  <mat-card>
    <mat-card-content>
      <mat-action-list>
        <app-music-list-item *ngFor="let music of musicList" [music]="music">
        </app-music-list-item>
      </mat-action-list>
    </mat-card-content>
  </mat-card>
  
</div>
