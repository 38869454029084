<app-toolbar [title]="i18n.get('aboutUsTitle')"></app-toolbar>
<mat-card >
    <mat-card-title>{{i18n.get('aboutUsCardTitle')}}</mat-card-title>
    <mat-card-subtitle>{{i18n.get('aboutUsCardSubTitle')}}</mat-card-subtitle>
    <mat-card-content>
        <div>
            <img [src]="xkLogoSrc" alt="" class="logos">
            <img [src]="seLogoSrc" alt="" class="logos logoSE">

        </div>
        <div style="margin-top: 1em;">
            <p>{{i18n.get('aboutUsText1')}}</p>
            <p>{{i18n.get('aboutUsText2')}}</p>
            <p>{{i18n.get('aboutUsText3')}}</p>
            <p>{{i18n.get('aboutUsText4')}}</p>
            <p>{{i18n.get('aboutUsText5')}}</p>

        </div>
    </mat-card-content>
</mat-card>

          <!-- <p>
                    Vous trouverez dans cette application tout une palette de Prods (Instrumental) qui
                    gravitent autour des différents styles de musique Rap que l’on retrouve dans la
                    culture Hip Hop.</p>
                <p>Commencez dès maintenant à écouter les échantillons : </p> -->
