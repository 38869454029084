
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable, BehaviorSubject, of, firstValueFrom } from 'rxjs';
import { environment } from './../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogContentComponent } from '../fragments/login-dialog-content/login-dialog-content.component';
import { LoginData, User } from '../util/interfaces';
import { CreateAccountDialogContentComponent } from '../fragments/create-account-dialog-content/create-account-dialog-content.component';
import { UpdateAccountDialogContentComponent } from '../fragments/update-account-dialog-content/update-account-dialog-content.component';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { I18n } from './i18n.service';
import { PopUpService } from './popup.service';

@Injectable({
    providedIn: "root"
})
export class UserService {
    loaded$: BehaviorSubject<boolean>
    isAuth: boolean = undefined;
    authToken: string | undefined;
    user: User | undefined;
    oEmptyUser: User;
    stayConnected: boolean = true;

    isAuthSubject: BehaviorSubject<boolean>;

    userInfoSubject = new Subject<object>()

    dialogData: LoginData = {
        email: "",
        password: "",
        stayConnected: true,
        lastname: "",
        firstname: "",
        confirmPasssword: "",
        deleteAccount: false,
        updatePassword: false,
        newPassword: ""
    };
    storageMode: Storage;

    constructor(private snackBar: PopUpService,
        private httpClient: HttpClient,
        private matDialog: MatDialog,
        public i18n: I18n,
    ) {
        this.loaded$ = new BehaviorSubject<boolean>(false);
        this.isAuthSubject = new BehaviorSubject<boolean>(this.isAuth);
        this.authToken = localStorage.getItem("token") || sessionStorage.getItem("token");
        this._initUser();
    }

    /* CRUD Methodes - API calls*/

    /**
     * Retrieve user data from the API thanks to stored token
     * - Return a User object if a token is available
     * - Return an error if no token available
     */
    public getUserInfo(): Observable<User> {
        return this.httpClient.get<User>(`${environment.API_ENDPOINT}/user`)
    }

    public isAdmin$(): Observable<boolean> {
        if (this.user?.isAdmin) return of(true);

    }

    public login(credential: User | LoginData) {
        const header = this._getBasicAuthHeader(credential.email, credential.password);
        return this.httpClient
            .get(`${environment.API_ENDPOINT}/user/login`, { headers: header })
            .pipe(
                catchError((error) => {
                    this.isAuth = false;
                    this.emitAuthSatus();

                    if (error.error.message === "user not found" || "password invalid" ||
                        error.error.message === "wrong password") {
                        this.snackBar.showError(this.i18n.get('wrongMailOrPassword'));
                    } else {
                        this.snackBar.showError(this.i18n.get('errorOccurs'));
                    }
                    return of(false);
                }),
                tap((user: User) => {
                    //console.log(answer);
                    if (user && user.token) {
                        this._storeAuthToken(user.token);
                        this.isAuth = true;
                        this.user = user;
                        this.emitUserInfoSubject();
                        this.emitAuthSatus();
                        this.snackBar.open(this.i18n.get('hello'));
                    }
                    this.emitAuthSatus();
                    return user;
                },

                ));

    }

    public logOut(displaySnackBar: boolean = true) {
        this._removeAuthToken();
        this.isAuth = false;
        this.user = { ...this.oEmptyUser };
        this.emitUserInfoSubject();
        this.emitAuthSatus();
        this._initUser(); // in order to get a new token

        if (displaySnackBar) {
            this.snackBar.open(this.i18n.get("loggedOut"));
        }
    }

    public createUser(user: User) {
        user.password = btoa(user.password); // encode base64
        return this.httpClient
            .post(`${environment.API_ENDPOINT}/user`, user)
            .pipe(map(
                (_user: User) => {
                    if (_user) {
                        this.user = _user;
                        this.isAuth = true;

                        this._storeAuthToken(_user.token)
                        this.emitAuthSatus();
                        this.emitUserInfoSubject();

                        this.snackBar.open(this.i18n.get('welcome'));
                        return user;
                    }
                },
                (error) => {
                    this.isAuth = false;
                    this.emitAuthSatus();
                    if (error.error.message === "email already existing") {
                        this.snackBar.showError(this.i18n.get('userAlreadyExisting'));
                    } else {
                        this.snackBar.showError(this.i18n.get('errorOccurs') + error.error.message);
                    }
                    return;
                }
            ));
    }


    public updateUser(userInfo: LoginData) {
        let snackMessage: string;
        const header = this._getBasicAuthHeader(userInfo.email, userInfo.password);
        // encode password
        delete userInfo.password;
        userInfo.newPassword = btoa(userInfo.newPassword);
        return this.httpClient
            .put(`${environment.API_ENDPOINT}/user/${this.user.id}`, userInfo, { headers: header })
            .pipe(map(
                (user: User) => {
                    //console.log(answer);
                    this.user = user;
                    this.emitUserInfoSubject();

                    this._storeAuthToken(user.token);

                    this.snackBar.open(this.i18n.get('userUpdated'));
                },

                (error) => {
                    if (error.error.message === 'password invalid') {
                        snackMessage = this.i18n.get('wrongPassword');
                    } else {
                        snackMessage = `${this.i18n.get('errorOccurs')} : ${error.error.message}`
                    }
                    this.snackBar.showError(snackMessage);
                }
            ));
    }

    public deleteUser(user: LoginData) {
        return new Observable((obs) => {
            //first get moficiation authorization token
            const userId = this.user.id;
            const headers = this._getBasicAuthHeader(user.email, user.password);
            // then request detion
            this.httpClient.delete(`${environment.API_ENDPOINT}/user/${userId}`, { headers: headers }).subscribe(
                () => {
                    this.logOut(false);
                    this.snackBar.open(this.i18n.get('user') + user.email + this.i18n.get('deleted'));
                    obs.next();
                    obs.complete();
                },
                (err) => {
                    this.snackBar.open(err.error.message);
                    obs.error();
                    obs.complete();

                }
            )
        }
        )
    }

    /**
     * Return a Basic authorization header
     * @param userId 
     * @param password 
     * @returns 
     */
    private _getBasicAuthHeader(email: string, password: string): HttpHeaders {
        return new HttpHeaders().append('Authorization', `Basic ${btoa(email)}:${btoa(password)}`)
    }

    /* Subjects */

    public emitAuthSatus() {
        if (this.isAuth !== undefined) {
            this.isAuthSubject.next(
                this.isAuth
            )
        } else {
            this.isAuthSubject.next(false);
        }
    }

    public emitUserInfoSubject() {
        this.userInfoSubject.next(
            this.user || {}
        )
    }

    /* Dialogs  */

    public async displayLoginDialog() {
        const dialogRef = this.matDialog.open(LoginDialogContentComponent, {
            data: this.dialogData
        });
        const loginData: "cancel" | LoginData = await firstValueFrom(dialogRef.afterClosed());
        if (loginData === "createAccount") {
            return await firstValueFrom(this.displayCreateUserDialog())
        } else if (loginData === "cancel") {
            this.clearDialogData();
            dialogRef.close();
            return false;
        } else {
            this.stayConnected = loginData.stayConnected;
            return firstValueFrom(this.login(loginData).pipe(tap(() => this._clearPassword())))
        }

    }

    public displayCreateUserDialog() {
        const dialogRef = this.matDialog.open(CreateAccountDialogContentComponent);

        return dialogRef.afterClosed().pipe(
            switchMap((signInData: any) => {
                if (signInData === "cancel") {
                    this.clearDialogData();
                    dialogRef.close();
                    return of(false);
                } else {
                    this.stayConnected = signInData.stayConnected;
                    return this.createUser(signInData)
                }
            }),
            tap(() => this._clearPassword())
        )
    }

    public displayUpdateUserDialog() {
        this.dialogData = this._initDialogData();
        const dialogRef = this.matDialog.open(UpdateAccountDialogContentComponent, {
            data: this.dialogData
        });

        dialogRef.afterClosed().subscribe((result: LoginData) => {
            if (result !== "cancel") {
                delete result.confirmPasssword
                if (result.newPassword === "" || !result.newPassword) {
                    result.newPassword = result.password;
                }
                result.deleteAccount ? this.deleteUser(result).subscribe() : this.updateUser(result).subscribe()
            }
        });
        this._clearPassword();
    }

    public clearDialogData() {
        this._clearPassword();
        this.dialogData.lastname = "";
        this.dialogData.email = "";
        this.dialogData.firstname = "";
        this.dialogData.updatePassword = false;
        this.dialogData.deleteAccount = false;
    }

    private _initUser() {
        this.getUserInfo().subscribe(
            (user) => {
                this.user = user;
                this._storeAuthToken(user.token);
                if (user.email) { // guest users have no address email
                    this.isAuth = true;
                } else {
                    this.isAuth = false;
                }
                this.emitUserInfoSubject();
                this.loaded$.next(true);
            },

            () => {
                this.isAuth = false;
                this.loaded$.next(true);
            },
            () => {
                this.isAuthSubject.next(this.isAuth)
            }
        )
    }

    private _initDialogData(): LoginData {
        return {
            firstname: this.user?.firstname || "",
            lastname: this.user?.lastname || "",
            email: this.user?.email || "",
            deleteAccount: false,
            stayConnected: this.stayConnected,
            updatePassword: false
        }
    }

    private _clearPassword() {
        this.dialogData.password = "";
        this.dialogData.confirmPasssword = "";
        this.dialogData.newPassword = "";
    }

    /* authentication token management */

    private _getStorageMode(): Storage {
        // default storage is local storage
        let storageMode = localStorage;
        if (!this.stayConnected) {
            storageMode = sessionStorage;
        }
        return storageMode;
    }

    private _storeAuthToken(token: string) {
        this._removeAuthToken();
        this._getStorageMode().setItem('token', token);
        this.authToken = token;
    }

    private _removeAuthToken() {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
    }
}