import { Component, OnInit } from '@angular/core';
import { MasterDataService } from 'src/app/services/master-data.service';
import { Music } from 'src/app/util/interfaces';

@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss']
})
export class SearchViewComponent implements OnInit {

  public musics: Music[];
  constructor(public masterDataService: MasterDataService) { }

  ngOnInit(): void {
    this.musics = this.masterDataService.getMusics();
  }
}
