<h2 mat-dialog-title>{{i18n.get('signInPopupTitle')}}</h2>

<form #f="ngForm">
    <mat-dialog-content>
        
        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('name')}}</mat-label>
                <input matInput [placeholder]="i18n.get('name')" name="lastname" ngModel>

            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('firstName')}}</mat-label>
                <input matInput [placeholder]="i18n.get('firstName')" name="firstname" ngModel>

            </mat-form-field>
        </p>

        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('emailAdress')}}</mat-label>
                <input type="email" matInput [placeholder]="i18n.get('emailAdress')" name="email" email ngModel required>

            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('password')}}</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" name="password" required minlength="6"
                ngModel #userPassword="ngModel">
                <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint class="app-warn" *ngIf="userPassword.errors?.minlength">{{i18n.get('sixDigitMin')}}</mat-hint>
            </mat-form-field><br />


            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('confrimPassword')}}</mat-label>
                <input matInput [type]="hide2 ? 'password' : 'text'" [formControl]="confirmedPassword" required>
                <button type="button" mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide2">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint class="app-warn" *ngIf="f.value.password !== confirmedPassword.value">{{i18n.get('confirmedPasswordError')}}</mat-hint>
            </mat-form-field>

        </p>
        <p>
            <mat-checkbox name="CGUread" [ngModel]="true" required>{{i18n.get('readAndAgreeTOU')}}</mat-checkbox> <br />
            <mat-checkbox name="stayConnected" [ngModel]="true" >{{i18n.get('stayConnected')}}
            </mat-checkbox><br>
        </p>
        <mat-expansion-panel style="max-width : 300px;">
            <mat-expansion-panel-header>
                <mat-panel-title style="font-size: 12px;">
                    {{i18n.get('TOUPanelTitle')}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{i18n.get('TOUPart1')}}<br />
                {{i18n.get('TOUPart2')}}<br />
                {{i18n.get('TOUPart3')}}<br />
                {{i18n.get('TOUPart4')}}
            </p>
        </mat-expansion-panel>


    </mat-dialog-content>

    <mat-dialog-actions>
        <button type="button" mat-button mat-dialog-close="cancel">{{i18n.get('cancel')}}</button>
        <button type="submit" mat-button [mat-dialog-close]="f.value"
            [disabled]="f.invalid || f.value.password !== confirmedPassword.value">{{i18n.get('validate')}}</button>
    </mat-dialog-actions>

</form>