import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface UserActionPayload {
    musicId: string | number;
    userAction: string;
}

@Injectable({ providedIn: 'root' })
export class StatService {
    constructor(private _httpClient: HttpClient) { }

    public recordUserAction(musicId: string | number, userAction: string) {
        const body: UserActionPayload = {
            musicId: musicId,
            userAction: userAction
        }
        return this._httpClient.post(`${environment.API_ENDPOINT}/stat`, body)
            .pipe(map(() => { }, (error: string) => { }));
    }

    public iLike(musicId: string) {
        this.recordUserAction(musicId, 'like').subscribe();
    }
}

