<app-toolbar [title]="i18n.get('contactViewTitle')"></app-toolbar>
<mat-card>
    <mat-card-title>Small Eyes</mat-card-title>
    <mat-card-subtitle>Beat Maker</mat-card-subtitle>
    <mat-card-content>
        <p>
            <a style="margin-right: 15px" href="https://www.tiktok.com/@small_eyes_records">
                <img class="logo" [src]="logoTikTokUrl" alt="Lien TikTok">
            </a>
            <a style="margin-right: 15px" href="https://www.instagram.com/smalleyes_nitrophonie">
                <img class="logo" [src]="logoInstaUrl" alt="Lien Instagram">
            </a>
            <a class="app-contrast" href="mailto:kevin.communal@gmail.com">
                <mat-icon class="" style="width: 40px; height: 40px; font-size: 45px; vertical-align: middle;;">
                    email
                </mat-icon>
            </a>

        </p>
        <p class="">{{i18n.get('contactViewText')}}</p>
        <div class="">

            <form #f="ngForm" (submit)="onSubmitContactForm(f)">

                    <mat-form-field appearance="outline" >
                        <mat-label>{{i18n.get('yourName')}}</mat-label>
                        <input matInput [placeholder]="i18n.get('yourName')" name="lastname" ngModel>

                    </mat-form-field>


                    <mat-form-field appearance="outline" >
                        <mat-label>{{i18n.get('yourFirstName')}}</mat-label>
                        <input matInput [placeholder]="i18n.get('yourFirstName')" name="firstname" ngModel>

                    </mat-form-field>



                    <mat-form-field appearance="outline" color="accent">
                        <mat-label>{{i18n.get('yourEmailAdress')}}</mat-label>
                        <input type="email" matInput [placeholder]="i18n.get('yourEmailAdress')" name="email" email
                           ngModel required>

                    </mat-form-field>
                    <p class="">

                        <mat-form-field appearance="outline" class="textArea" color="accent">
                            <mat-label>{{i18n.get('yourMessage')}}</mat-label>
                            <textarea rows="8" matInput [placeholder]="i18n.get('yourMessage')" name="text" ngModel required></textarea>
                          </mat-form-field>
                    </p>

                <p>
                    <button type="button" mat-stroked-button (click)="back()">{{i18n.get('cancel')}}</button>
                    <button type="submit" mat-flat-button color="primary" [disabled]="f.invalid">{{i18n.get('send')}}</button>
                </p>

            </form>

        </div>


    </mat-card-content>
</mat-card>
