<div #main class="app-nav-bar ">
    <div class="nav-row">
        <span matRipple [matRippleRadius]="40" [matRippleCentered]="true" [matRippleUnbounded]="true"
            (click)="navTo('/home')">
            <div>
                <a class="app-nav-bar-link" routerLink="home" routerLinkActive="active-link">
                    <mat-icon>home</mat-icon><br />
                    <span class="mat-body-2 small">{{i18n.get('navHome')}}</span>
                </a>
            </div>
        </span>
        <!-- 
    <span matRipple [matRippleRadius]="40" [matRippleCentered]="true" [matRippleUnbounded]="true"
        (click)="navTo('/search')">
        <div>
            <a class="app-nav-bar-link" routerLink="search" routerLinkActive="active-link">
                <mat-icon>search</mat-icon><br />
                <span class="small">{{i18n.get('navSearch')}}</span>
            </a>
        </div>
    </span> -->

        <span *ngIf="isPlaylistDisplayed()" matRipple [matRippleRadius]="40" [matRippleCentered]="true" [matRippleUnbounded]="true"
            (click)="navTo('/categories')">
            <div>
                <a class="app-nav-bar-link" routerLink="categories" routerLinkActive="active-link">
                    <mat-icon>apps</mat-icon><br />
                    <span class="mat-body-2 small">{{i18n.get('navStyles')}}</span>
                </a>
            </div>
        </span>

        <span matRipple [matRippleRadius]="40" [matRippleCentered]="true" [matRippleUnbounded]="true"
            (click)="navTo('/favorites')">
            <div>
                <a class="app-nav-bar-link" routerLink="favorites" routerLinkActive="active-link">
                    <mat-icon>favorite_border</mat-icon><br />
                    <span class="mat-body-2 small">{{i18n.get('navFavorites')}}</span>
                </a>
            </div>
        </span>

        <span matRipple [matRippleRadius]="40" [matRippleCentered]="true" [matRippleUnbounded]="true"
            (click)="sidenav.toggle()">
            <div style="text-align: center;">
                <a class="app-nav-bar-link" routerLinkActive="active-link">
                    <mat-icon>menu</mat-icon><br />
                    <span class="mat-body-2 small">{{i18n.get('navMenu')}}</span>
                </a>
            </div>
        </span>
    </div>
</div>