import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MasterDataService } from './services/master-data.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AudioPlayerService } from './services/audioPlayer.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { UserService } from './services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { PlayerComponent } from './components/player/player.component';
import { CategoriesViewComponent } from './views/categories-view/categories-view.component';
import { CategoryDetailViewComponent } from './views/category-detail-view/category-detail-view.component';
import { CreateAccountDialogContentComponent } from './fragments/create-account-dialog-content/create-account-dialog-content.component';
import { LoginDialogContentComponent } from './fragments/login-dialog-content/login-dialog-content.component';
import { PrivateDataInfoDialogContentComponent } from './fragments/private-data-info-dialog-content/private-data-info-dialog-content.component';
import { PromptLoginDialogContentComponent } from './fragments/prompt-login-dialog-content/prompt-login-dialog-content.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { NotFoundViewComponent } from './views/not-found-view/not-found-view.component';
import { ContactViewComponent } from './views/contact-view/contact-view.component';
import { AboutAppViewComponent } from './views/about-app-view/about-app-view.component';
import { MusicListItemComponent } from './components/music-list-item/music-list-item.component';
import { DossierItemComponent } from './components/category-button/category-button.component';
import { PlayListComponent } from './views/favorites-view/play-list.component';
import { UpdateAccountDialogContentComponent } from './fragments/update-account-dialog-content/update-account-dialog-content.component';
import { NotificationService } from './services/notification.service';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { PopUpService } from './services/popup.service';
import { SettingsDialogComponent } from './fragments/settings-dialog/settings-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OrderComponent } from './views/order/order.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { ThanksViewComponent } from './views/thanks-view/thanks-view.component';
import { PlayerControlViewComponent } from './views/player-control-view/player-control-view.component';
import { SearchViewComponent } from './views/search-view/search-view.component';
import { I18n } from './services/i18n.service';
import { initializeI18n, initializeMusicData } from './app.initialize';
import { LanguageDialogComponent } from './fragments/language-dialog/language-dialog.component';
import { HomeViewComponent } from './views/home-view/home-view.component';
import { MenuComponent } from './components/menu/menu.component';
import { SocialShareComponent } from './components/social-share-component/social-share-component';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SocialShareService } from './services/socialShare.service';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { ThemeService } from './services/theme.service';
import { AppInterceptor } from './services/http-interceptor';
import { CategoryThumbnailComponent } from './components/category-thumbnail/category-thumbnail.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { AdminGuard } from './services/auth-guard.service';
import { AppMaterialModule } from './modules/material-module/material.module';
import { MusicDetailsViewComponent } from './views/music-details-view/music-details-view.component';
import { CopyrightViewComponent } from './views/copyright-view/copyright-view.component';
import { AppPaypalComponent } from './components/app-paypal/app-paypal.component';
import { PopupToConfirmComponent } from './fragments/popup-to-confirm/popup-to-confirm.component';
import { Web3Component } from './components/web3/web3.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    PlayerComponent,
    MusicListItemComponent,
    DossierItemComponent,
    CategoriesViewComponent,
    CategoryDetailViewComponent,
    PlayerComponent,
    CreateAccountDialogContentComponent,
    LoginDialogContentComponent,
    ContactViewComponent,
    CreateAccountDialogContentComponent,
    PrivateDataInfoDialogContentComponent,
    PromptLoginDialogContentComponent,
    ContactViewComponent,
    AboutUsComponent,
    AboutAppViewComponent,
    ToolbarComponent,
    NotFoundViewComponent,
    PlayListComponent,
    UpdateAccountDialogContentComponent,
    SettingsDialogComponent,
    OrderComponent,
    ThanksViewComponent,
    PlayerControlViewComponent,
    SearchViewComponent,
    LanguageDialogComponent,
    HomeViewComponent,
    CategoryThumbnailComponent,
    MenuComponent,
    SocialShareComponent,
    MusicDetailsViewComponent,
    CopyrightViewComponent,
    AppPaypalComponent,
    PopupToConfirmComponent,
    Web3Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // ServiceWorkerModule.register('combined-sw.js', {enabled: environment.production}),
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: location.hostname !== 'localhost'
    }),
    BrowserAnimationsModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxPayPalModule,
    ShareButtonsModule,
    ShareIconsModule
  ],
  providers: [

    {
      provide: APP_INITIALIZER,
      useFactory: initializeI18n,
      deps: [Injector],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeMusicData,
      deps: [Injector],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    MasterDataService,
    PlayerComponent,
    AudioPlayerService,
    UserService,
    AdminGuard,
    MatSnackBar,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    ThemeService,
    NotificationService,
    PopUpService,
    I18n,
    SocialShareService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(librairy: FaIconLibrary) {
    librairy.addIcons(faInstagram)
    librairy.addIcons(faFacebookF)
  }
}

