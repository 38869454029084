<mat-toolbar class="stickyTop">

    <button mat-icon-button (click)="onClickReturn()">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <span class="custo-spacer"></span>
    <span>{{title}}</span>
    <span class="custo-spacer"></span>
    <span style="width: 40px;">
    </span>

</mat-toolbar>