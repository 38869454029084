import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../environments/environment';
import { Router, RouterOutlet, NavigationStart } from '@angular/router';
import { AudioPlayerService } from './services/audioPlayer.service';
import { componentAnimation, componentSlideUpAnimation, playerComponentAnimation, slideInAnimation } from './util/animation';
import { NotificationService } from './services/notification.service';
// import { MatSearchBarComponent } from 'ng-mat-search-bar/src/app/ng-mat-search-bar/mat-search-bar/mat-search-bar.component';
import { MasterDataService } from './services/master-data.service';
import { Music } from './util/interfaces';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { I18n } from './services/i18n.service';
import { PopUpService } from './services/popup.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { ThemeService } from './services/theme.service';
import { SocialShareService } from './services/socialShare.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation,
    componentSlideUpAnimation, playerComponentAnimation, componentAnimation]

})
export class AppComponent implements OnInit, AfterViewInit {
  public searchTerm: string;
  public logoHeaderLightThemeUrl = environment.ASSETS + '/images/' + environment.imagesFileName.logoHeaderLightThemeUrl;
  public logoHeaderDarkThemeUrl = environment.ASSETS + '/images/' + environment.imagesFileName.logoHeaderDarkThemeUrl;
  public searchFieldFormControl = new UntypedFormControl();
  public isDarkTheme: boolean;

  filteredOptions: Observable<Music[]>;
  // @ViewChild("matSearchBar") private searchBar: MatSearchBarComponent;

  constructor(
    private router: Router,
    private audioPlayer: AudioPlayerService,
    private notificationService: NotificationService,
    private masterDataService: MasterDataService,
    private i18n: I18n,
    private popupService: PopUpService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private swUpdate: SwUpdate,
    private themeService: ThemeService,
    public socialShareService: SocialShareService
  ) { }


  ngOnInit(): void {
    // prenvent right click in order not to allow image copying
    document.addEventListener('contextmenu', event => event.preventDefault());
    this._checkAppUpdate();
    this._initCustomIcon();
    this._initSearchBar();

    this._initTheme();
    /** Lang selection in case of autodetection failed **/
    if (this.i18n.isPreferedLangIdentified === false) {
      this._promptLanguageSelection().onChangeLangEvent.subscribe((lang) => this.i18n.setLang(lang))
    }
  }

  ngAfterViewInit(): void {
    this.removeInitLoader()
  }

  private _initTheme() {
    this.themeService.isDarkTheme$().subscribe((isDark) => {
      if (isDark) {
        this.isDarkTheme = true;
      } else {
        this.isDarkTheme = false;
      }
    })
  }

  /**
   * Refresh app in case of update available
   */
  private _checkAppUpdate() {
    this.swUpdate.available.subscribe(() => {
      this.popupService.showSnackBarTop('New version ! Reaload...');
      setTimeout.bind(this)(() => {
        window.location.reload()
      }, 1000);
    })
  }

  private _initSearchBar() {
    this.notificationService.listenForeGroundPushMessages();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.audioPlayer.isPlayerControlDisplayed = false;
        // if (event.url !== '/search' && this.router.url === '/search') {
        //   this.searchBar.close();
        // }
        // if (event.url === '/search') {
        //   setTimeout.bind(this)(() => {
            // this.searchBar.open();
        //   }, 0)
        // }
      }
    });

    /** Search Field subscription initialization **/
    this.masterDataService.subscribeSearchFieldValue(this.searchFieldFormControl.valueChanges);
  }

  private _initCustomIcon() {
    const aCustoIcons =
      [
        {
          icon: "Aleatoire.svg",
          name: "c_random"
        },
        {
          icon: "Avance.svg",
          name: "c_next"
        },
        {
          icon: "Boucle.svg",
          name: "c_loop"
        },
        {
          icon: "Favori 0.svg",
          name: "c_favorite_full"
        },
        {
          icon: "Favori 1.svg",
          name: "c_favorite_empty"
        },
        {
          icon: "Pause.svg",
          name: "c_pause"
        },
        {
          icon: "Play.svg",
          name: "c_play"
        },
        {
          icon: "Retour.svg",
          name: "retour"
        },
        {
          icon: "Panier.svg",
          name: "cart"
        },
        {
          icon: "headphone.svg",
          name: "headphone"
        },
        {
          icon: "Mix-Mastering-Ico.svg",
          name: "mix"
        },
        {
          icon: "Consulting-Ico.svg",
          name: "consulting"
        },
        {
          icon: "Direction Artistique-Ico.svg",
          name: "ampoule"
        },
        {
          icon: "BeatMaking-Ico.svg",
          name: "beatmaking"
        },

      ]

    for (let newIcon of aCustoIcons) {
      this.matIconRegistry.addSvgIcon(
        newIcon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(environment.ASSETS + '/icons/' + newIcon.icon))
    }
  }

  private _promptLanguageSelection() {
    return this.popupService.displayDialog("LanguageDialogComponent", {
      lang: this.i18n.lang,
      availableLang: this.i18n.langLabels
    })
  }

  // For transition animations
  public prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  public getDisplayLogo() {
    return this.router.url === '/search' ? false : true;
  }

  public getDisplayPlayer() {
    if (this.router.url.startsWith('/player')) {
      return false;
    } else {
      return this.audioPlayer.getState().music.id !== 0 ? true : false;
    }
  }

  public isSearchBarDisplayed() {
    return this.router.url.startsWith('/search') ? true : false;
  }

  public onCloseSearchBar() {
    this.searchFieldFormControl.reset();
  }

  public onFocusSearchBar() {
    this.router.navigate(['search'])
  }

  public onClickSettings() {
    this.notificationService.displaySettingsPopup();
  }

  public onClickToogleTheme() {
    this.themeService.toogleTheme();
  }

  public isPlayerControlDisplayed() {
    return this.audioPlayer.isPlayerControlDisplayed
  }

  public removeInitLoader() {
    // document.getElementById('initLoader').setAttribute('style', 'display: none;') 
    document.getElementById('initLoader').style.display = 'none';
  }
}
