import { Observable } from "rxjs";

export interface MasterData {
    musics: Music[],
    categories: Category[],
    sources: MusicSourceObj[],
    settings: AppSettings[]
}


export interface User {
    id?: number,
    email?: string,
    lastname?: string,
    firstname?: string,
    password?: string,
    isAdmin?: boolean,
    token?: string,
    token_creation_date?: string;
    creation_date: string;

};

export interface LoginData {
    email?: string;
    password?: string;
    confirmPasssword?: string;
    lastname?: string;
    firstname?: string;
    newPassword?: string;
    stayConnected?: boolean;
    updatePassword?: boolean;
    deleteAccount?: boolean;
    superToken?: string;
}

export interface ContactForm {
    email: string;
    firstname: string;
    lastname: string;
    text: string;
}

export interface SettingDialogData {
    isNotificationActivated:  Observable<boolean>,
    bLoader: Observable<boolean>,
    lang: string,
    availableLang: any
    isDarkTheme: Observable<boolean>
}

export interface Music {
    id?: number;
    categoryId?: number;
    title?: string;
    data?: MusicData
}

export interface MusicData {
    sort?: number;
    categorySort?: number;
    description?: string;
    tempo?: string;
    tone?: string;
    duration?: string;
    picture?: string;
    price?: number;
    priceEth? : number;
}

export interface Category {
    id?: number,
    name?: string,
    sort?: number,
    picture?: string,
    picture_title?: string
    // front only
    crud?: string;
}

export interface MusicSourceObj {
    id?: number,
    music_id?: number,
    src?: string,
    type?: string
}

export interface AppSettings {
    name: string,
    value: string
}

export interface AudioPlayerState {
    music?: Music,
    folder?: number,
    currentTime?: number,
    playing?: boolean,
    newTrack?: boolean,
    repeat?: string
}


export interface PayPalOrder {
    intent: 'CAPTURE' | 'AUTHORIZE', // requiered
    purchase_units: PayPalPurchaseUnit[]
}


export interface PayPalPurchaseUnit {
    description?: string,
    amount: {
        value: string,
        currency_code: string,
        breakdown: any,
    },
    items?: PayPalItemObject[],
}

export interface PayPalItemObject {
    name: string,
    unit_amount: any, // '50.00',
    quantity: string,
    description?: string,
    sku?: string,
    category?: 'DIGITAL_GOODS' | 'PHYSICAL_GOODS',
}

export interface OrderParams {
    price: number;
    priceEth?: number;
    article: string;
    category: string;
  }

  export interface MasterDataCache {
      musics: {
          [musicId: number] : Music
      }
      categories: {
          [categoryId: number] : Category
      }
  }
