import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { MasterDataService } from './master-data.service';
import { PopUpService } from './popup.service';
import { I18n } from './i18n.service';
import { AudioPlayerState, Music } from '../util/interfaces';
import { StatService } from "./stat.service";


@Injectable({
    providedIn: "root"
})
export class AudioPlayerService {
    public isPlayerControlDisplayed: boolean = false;
    public firstPlay = true;
    public currentTime: number;
    public duration: number;
    public loading: boolean;
    private state: AudioPlayerState = {
        music: { id: 0, categoryId: 0, title: "", data: {} },
        folder: 0,
        currentTime: 0,
        playing: false,
        newTrack: false,
        repeat: "no"
    }
    private trackList: Music[];


    //-----Subjects------//
    public currentTimeSubject = new Subject<number>();
    public durationSubject = new Subject<number>();
    public loadingSubject = new Subject<boolean>();
    public stateSubject = new Subject<object>();

    // audio
    private audioObj = new Audio();
    private audioEvents = [
        "ended",
        "error",
        "play",
        "playing",
        "pause",
        "timeupdate",
        "canplay",
        "loadedmetadata",
        "loadstart",
        "progress",
        "waiting"
    ];

    constructor(private masterDataService: MasterDataService,
        private statService: StatService,
        private popupService: PopUpService,
        public i18n: I18n) {
        // Add audio event listener
        for (let i = 0; i < this.audioEvents.length; i++) {
            this.audioObj.addEventListener(this.audioEvents[i], this.audioEventsHandler);
        }
    }

    private audioEventsHandler = (event: Event) => {
        switch (event.type) {
            case "timeupdate":
                if (this.audioObj.readyState === 1 || this.audioObj.readyState === 4) { // HAVE_METADATA iOs bug fix
                    this.emitCurrentTimeSubject();
                }
                break;

            case "loadedmetadata":
                if (this.audioObj.readyState === 1 || this.audioObj.readyState === 4) { // HAVE_METADATA iOs bug fix
                    this.emitDurationSubject();
                }
                break;

            case "ended":
                this.setPlayingState(false);
                this.playNext();
                this.statService.recordUserAction(this.state.music.id.toString(), "play_end").subscribe();;
                break;
            case "canplay":
                this.setLoadingState(false);

                if (this.audioObj.readyState === 1 || this.audioObj.readyState === 4) { // HAVE_METADATA iOs bug fix
                    this.emitDurationSubject();
                }
                if (this.audioObj.currentTime === 0) {
                    this.statService.recordUserAction(this.state.music.id.toString(), "play_start").subscribe();
                }
                break;
            case "loadstart":
                this.setLoadingState(true);
                break;
            case "waiting":
                this.setLoadingState(true);
                break;
            case "error":
                this.setLoadingState(false);
                this.popupService.showSnackBarTop(this.i18n.get('loadingError'))
                break;
        }
    }

    private emitCurrentTimeSubject() {
        this.currentTimeSubject.next(
            this.audioObj.currentTime
        );
        this.currentTime = this.audioObj.currentTime
    }

    private emitDurationSubject() {
        this.durationSubject.next(
            this.audioObj.duration
        );
        this.duration = this.audioObj.duration
    }

    private setLoadingState(bool: boolean) {
        this.loading = bool;
        this.loadingSubject.next(bool);
    }

    public emitStateSubject() {
        this.stateSubject.next(
            this.state
        )
    }

    //-----audio controls------//
    public play() {
        if (this.firstPlay) {
            this.isPlayerControlDisplayed = true;
            this.firstPlay = false;
        }
        this.audioObj.play();
        this.setPlayingState(true);
        this.emitStateSubject();
        this._handleRemoteAudioControl()
    }

    private _handleRemoteAudioControl() {
        if ("mediaSession" in navigator) {
            // @ts-ignore
            navigator.mediaSession.metadata = new MediaMetadata({
                title: this.getCurrentMusicTitle(),
                artist: 'Small Eyes',
                album: this.masterDataService.getCategoryNameById(+this.state.music.categoryId),
                artwork: [
                    { src: this.getPictureSource(), sizes: '512x512', type: 'image/png' }
                ]
            });
            // @ts-ignore
            navigator.mediaSession.setActionHandler('previoustrack', this.playPrevious.bind(this));
            // @ts-ignore
            navigator.mediaSession.setActionHandler('nexttrack', this.playNext.bind(this));
        }
    }

    public pause() {
        this.audioObj.pause();
        this.setPlayingState(false);
    }

    public playIt(music: Music, list?: string) {

        if (list == "all") {
            this.trackList = this.masterDataService.getMusics();
            this.trackList.sort(this._sortBySortNumber);
        } else if (list == "favorites") {
            this.trackList = this.masterDataService.playList;
        } else if (list == "categories") {
            this.trackList = this.masterDataService.getMusicsByCategoryId(music.categoryId);
            this.trackList.sort(this._sortByCategorySortNumber);
        }

        this.setLoadingState(true);
        this.audioObj.src = this.masterDataService.getSources(music.id);
        this.audioObj.load();
        this.state.music = music;
        this.play();
    }

    public playNext() {
        if (!this.trackList) {
            this.trackList = this.masterDataService.getMusics();
        }
        for (let i = 0; i < this.trackList.length; i++) {
            if (this.state.music.id == this.trackList[i].id) {
                if (this.trackList[i + 1]) {
                    this.playIt(this.trackList[i + 1]);
                    break;
                }
            }
        }
    }

    public playPrevious() {
        if (!this.trackList) {
            this.trackList = this.masterDataService.getMusics();
        }
        for (let i = 0; i < this.trackList.length; i++) {
            if (this.state.music.id == this.trackList[i].id) {
                if (this.trackList[i - 1]) {
                    this.playIt(this.trackList[i - 1]);
                    break;
                }
            }
        }
    }

    public repeat() {
        if (this.state.repeat === "no") {
            this.audioObj.loop = true;
            this.state.repeat = "one";
        } else if (this.state.repeat === "one") {
            this.audioObj.loop = false;
            this.state.repeat = "no";
        }
    }

    public onSeek(value: number) {
        this.audioObj.currentTime = value;
    }

    //------ getters ----------//
    public getCurrentMusicTitle() {
        if (this.state !== undefined) {
            return this.state.music.title;
        } else return "";
    }

    public getState() {
        return this.state;
    }

    public getMusicItem() {
        return this.state.music;
    }

    private getPictureSource() {      
        return this.masterDataService.getDefaultPictureByMusicId(this.state.music.id);
    }

    //-----Setter------//
    private setPlayingState(bool: boolean) {
        this.state.playing = bool;
    }

    public getPlayingSate() {
        return this.state.playing;
    }

    //----- Util -------
    private _sortByCategorySortNumber(a: Music, b: Music) {
        return a.data.categorySort - b.data.categorySort;
    }

    private _sortBySortNumber(a: any, b: any) {
        return a.sort - b.sort;
    }
}

