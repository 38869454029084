<app-toolbar [title]="i18n.get('theApp')"></app-toolbar>
<mat-card>
    <mat-card-title>Small Eyes Records</mat-card-title>
    <mat-card-subtitle>{{i18n.get('webApp')}} - {{i18n.get('appVersion')}}</mat-card-subtitle>
    <mat-card-content>
        <!-- <P>Comment utiliser l'appli : <button mat-raised-button>Appyuez ici</button></P> -->
        <p>{{i18n.get('aboutUsPart-1')}}</p>
        <p>{{i18n.get('aboutUsPart0')}}</p>
        <p>{{i18n.get('aboutUsPart1')}}
            <mat-icon>more_vert</mat-icon>{{i18n.get('aboutUsPart2')}}
        </p>
        <p>{{i18n.get('aboutUsPart3')}}</p>
        <button class="smallMargin smallText" mat-stroked-button
            (click)="onClickMentionsLegalesButton()">{{i18n.get('TOUButtonLabel')}}</button>
        <button mat-stroked-button color="warn" (click)="reLoad()">{{i18n.get('rebootApp')}}</button>
        <mat-spinner *ngIf="isLoading" diameter="56"></mat-spinner>


    </mat-card-content>
</mat-card>