<div class="mainDisplay backGroundGradient">
  <div class="homeSpacer"></div>
  <div class="vignetteContainer" *ngIf="getTopMusic()">
    <img *ngIf=" getTopMusic()?.data.picture" [src]="getTopMusicPictureUrl()" class="TopVignetteComponentClass">
    <app-category-thumbnail *ngIf="!getTopMusic()?.data.picture && getTopMusicCategory()"
      class="TopVignetteComponentClass" [category]="getTopMusicCategory()"></app-category-thumbnail>
  </div>


  <div class="actionContainer" *ngIf="getTopMusic()">
    <div class="smallMargin">N° {{getTopMusicRanking()}}</div>
    <div class="smallMargin topMusicTitle mat-title">{{getTopMusic()?.title}}</div>

    <div class="horizontalFlex horizontalMargin">
      <!-- <span class="flex1">
        <a mat-icon-button color="primary" href="https://www.instagram.com/smalleyes_nitrophonie" target="_blank">
          <mat-icon class="iconNotMaterial">
            <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
          </mat-icon>
        </a>
        <a mat-icon-button color="primary" href="https://facebook.com/Small-Eyes-Music-315074759254623/"
          target="_blank">
          <mat-icon class="iconNotMaterial">
            <fa-icon [icon]="['fab', 'facebook-f']"></fa-icon>
          </mat-icon>
        </a>
      </span> -->


      <button (click)="playTopMusique()" class="mainButton " mat-raised-button color="primary">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <span class="main-button-icon">
            <mat-icon>play_arrow</mat-icon>
          </span>
          <span class="">{{i18n.get('play')}}</span>
          <span class="custo-spacer"></span>
        </div>
      </button>

      <!-- <div class="flex1 alignItemLeft">
        <button mat-icon-button color="primary" (click)="socialShareService.openSocialShareCpnt()"
          class="icon-button-intermediate smallMarginLeft">
          <mat-icon class="svg mat-icon40">share</mat-icon>
        </button>
      </div> -->
    </div>
    <!-- <p>{{i18n.get("homeMessage")}}</p> -->


  </div>
  <div class="homeSpacer"></div>

  <div class="scrollSectionTitle">
    <h2>{{i18n.get('styleViewTitle')}}</h2>
  </div>
  <div class="scrollOverflowHorizContainer">
    <span class="vignetteContainerBottom smallPadding" *ngFor="let category of categories">
      <app-category-thumbnail [opacity]="1" matRipple (click)="navTo('/categories/' + category.id.toString())" class=""
        [category]="category"></app-category-thumbnail>
    </span>
  </div>
</div>