<h2 mat-dialog-title>{{i18n.get('updateCredential')}}</h2>

<form  #updateForm="ngForm">
    <mat-dialog-content>
        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('name')}}</mat-label>
                <input matInput [placeholder]="i18n.get('name')" name="userLastName" [(ngModel)]="data.lastname">

            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('firstName')}}</mat-label>
                <input matInput [placeholder]="i18n.get('firstName')" name="userfirstName" [(ngModel)]="data.firstname">

            </mat-form-field>
        </p>

        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('emailAdress')}}</mat-label>
                <input matInput [placeholder]="i18n.get('emailAdress')" name="userMail" [(ngModel)]="data.email" required>

            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('password')}}</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" name="userPassword" [(ngModel)]="data.password" required>
                <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
        </p>
        <p>
            <mat-slide-toggle  [checked]="updatePasswordSlide" [(ngModel)]="data.updatePassword" name="updatePassword">
                {{i18n.get('updatePassword')}}
            </mat-slide-toggle>
        </p>
        <p *ngIf="data.updatePassword" > 
            <mat-form-field  appearance="outline" color="accent">
                <mat-label>{{i18n.get('newPassword')}}</mat-label>

                <input matInput [type]="hide2 ? 'password' : 'text'" name="newPassword" required minlength="6"
                    [(ngModel)]="data.newPassword" #newPassword="ngModel">

                <button type="button" mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint class="app-warn" *ngIf="newPassword.errors?.minlength">{{i18n.get('sixDigitMin')}}</mat-hint>
            </mat-form-field>
        </p>
        <p *ngIf="data.updatePassword" >
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('confrimNewPassword')}}</mat-label>
               
                <input matInput [type]="hide3 ? 'password' : 'text'" name="confirmPasssword" [(ngModel)]="data.confirmPasssword" required>
              
                <button type="button" mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint class="app-warn" *ngIf="updateForm.value.newPassword !== updateForm.value.confirmPasssword">{{i18n.get('confirmedPasswordError')}}</mat-hint>
            </mat-form-field>
        </p>

        <mat-checkbox name="deleteAccount" [(ngModel)]="data.deleteAccount">{{i18n.get('deleteAccount')}}
        </mat-checkbox><br>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button type="button" mat-button mat-dialog-close="cancel">{{i18n.get('cancel')}}</button>
        <button type="submit" mat-button [mat-dialog-close]="data"
            [disabled]="updateForm.invalid || updateForm.value.newPassword !== updateForm.value.confirmPasssword">{{i18n.get('validate')}}</button>
    </mat-dialog-actions>

</form>