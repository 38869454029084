import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { I18n } from 'src/app/services/i18n.service';
import { LoginData } from 'src/app/util/interfaces';

@Component({
  selector: 'app-create-account-dialog-content',
  templateUrl: './create-account-dialog-content.component.html',
  styleUrls: ['./create-account-dialog-content.component.scss']
})
export class CreateAccountDialogContentComponent implements OnInit {
  public hide = true;
  public hide2 = true;
  public password = "";
  public stayConnected = true;
  public confirmedPassword = new UntypedFormControl('');

  constructor(@Inject(MAT_DIALOG_DATA) public data: LoginData, public i18n: I18n) { }

  ngOnInit(): void {
  }

}
