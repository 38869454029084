import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18n } from 'src/app/services/i18n.service';
// import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { MasterDataService } from 'src/app/services/master-data.service';
import { Music, OrderParams, PayPalOrder } from 'src/app/util/interfaces';
import { scrollDown } from 'src/app/util/utils';
import { environment } from 'src/environments/environment';

declare var paypal: any;

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  private payPalScriptSrc: string;

  public displayPaiement: boolean = false;
  public orderParams: OrderParams;


  constructor(private route: ActivatedRoute,
    private masterDataService: MasterDataService,
    public i18n: I18n,) {
    this.payPalScriptSrc = environment.payPalScriptSrc + environment.payPalParamsSrc;
  }

  ngOnInit(): void {
    //get url parameter
    const musicId = +this.route.snapshot.params['id'];
    // make ure data are available
    this.masterDataService.getData().subscribe(() => {
      // prepare the order payload
      const article = this.masterDataService.getMusicById(musicId);
      const category = this.masterDataService.getCategoryNameById(article.categoryId);;

      this.orderParams = {
        article: article.title,
        price: article.data.price,
        priceEth: article.data.priceEth,
        category: category
      };
    })
  }


}

