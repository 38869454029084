<div class="app-player-control">

  <mat-toolbar class="playerToolBar">
    <button class="app-accent" mat-icon-button (click)="onClickReturn()">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </mat-toolbar>

  <div class="playerPicture">
    <img *ngIf="musicItem.data.picture" [src]="getMusicPictureUrl(musicItem)" class="picture">

    <app-category-thumbnail *ngIf="!musicItem.data.picture" [displayGradient]="false" [category]="getCategory()"
      class="height100">
    </app-category-thumbnail>
  </div>

  <div class="vertivalSpacer"></div>
  <div class="player-control-container">
    <h2> {{getMusicTitle()}}</h2>
    <h3 class="mat-display-4">{{getCategory()?.name}}</h3>


    <!-- <button [disableRipple]="true" [matRippleUnbounded]="true" [matRippleCentered]="true" [matRippleRadius]="40"
            matRipple mat-icon-button (click)="socialShareService.openSocialShareCpnt()"
            class="icon-button-intermediate smallMarginRight">
            <mat-icon class="svg mat-icon40">share</mat-icon>
          </button>

          <button [disableRipple]="true" [matRippleUnbounded]="true" [matRippleCentered]="true" [matRippleRadius]="40"
            matRipple mat-icon-button (click)="onClickCartButton()" class="icon-button-intermediate ">
            <mat-icon class="svg mat-icon40">shopping_cart</mat-icon>
          </button> -->

    <!-- </mat-card-subtitle> -->
    <!-- <mat-card-content> -->

    <div class="slideBarContainer">
      <span class="counter">
        {{getCurrentTime()}}
      </span>
      <span class="slider">
        <mat-slider class="matSliderPlacement" thumbLabel [displayWith]="formatTime" min="0" [max]="getDuration()"
          [value]="sliderValue" (change)="onChangeSliderValue($event.value)" (input)="onMoovingThumb($event)"
          (touchend)="onTouchEnd()">
        </mat-slider>
      </span>
      <span class="counter">
        {{getDurationFormated()}}
      </span>
    </div>

    <mat-grid-list cols="5" rowHeight="72px">

      <mat-grid-tile (click)="onClickRepeat()" [colspan]="1" matRipple [matRippleRadius]="50" [matRippleCentered]="true"
        [matRippleUnbounded]="true">
        <button [disableRipple]="true" mat-icon-button class="icon-button-small">
          <mat-icon [svgIcon]="getRepeatIcon()" class="iconNotMaterial" [class.icon-active]="isRepeatActivated()">
          </mat-icon>
        </button>
      </mat-grid-tile>

      <mat-grid-tile (click)="onClickPrevious()" [colspan]="1" matRipple [matRippleRadius]="50"
        [matRippleCentered]="true" [matRippleUnbounded]="true">
        <button mat-icon-button [disableRipple]="true" [disabled]="getDisabledControlButtons()"
          class="icon-button-small">
          <mat-icon class="iconNotMaterial" svgIcon="retour">
          </mat-icon>
        </button>
      </mat-grid-tile>

      <mat-grid-tile (click)="onClickPlay()" [colspan]="1" matRipple [matRippleRadius]="50" [matRippleCentered]="true"
        [matRippleUnbounded]="true" [matRippleDisabled]="getDisabledControlButtons()">
        <button [disableRipple]="true" *ngIf="!loading" mat-icon-button class="icon-button-large">
          <mat-icon [svgIcon]="getPlayIcon()" class="iconNotMaterial"></mat-icon>
        </button>
        <mat-spinner [diameter]="54" color="accent" *ngIf="loading"></mat-spinner>
      </mat-grid-tile>

      <mat-grid-tile (click)="onClickNext()" [colspan]="1" matRipple [matRippleRadius]="50" [matRippleCentered]="true"
        [matRippleUnbounded]="true">
        <button [disableRipple]="true" mat-icon-button [disabled]="getDisabledControlButtons()"
          class="icon-button-small">
          <mat-icon svgIcon="c_next" class="iconNotMaterial"></mat-icon>
        </button>
      </mat-grid-tile>

      <mat-grid-tile (click)="handleFavorite()" [colspan]="1" matRipple [matRippleRadius]="50"
        [matRippleCentered]="true" [matRippleUnbounded]="true">
        <button [disableRipple]="true" mat-icon-button class="icon-button-small">
          <mat-icon [svgIcon]="getFavoriteIcon()" class="iconNotMaterial"></mat-icon>
        </button>
      </mat-grid-tile>

    </mat-grid-list>

  </div>


  <div class="primary-button-container">
    <button color="primary" mat-raised-button [routerLink]="'music/' + audioPlayerService.getState().music.id"
      (click)="leavePlayerControl()">{{i18n.get('seeDetails')}}</button>
  </div>

</div>