import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { defer, from, Observable, of } from 'rxjs';

@Injectable()
export class AdminGuard implements CanActivate, CanLoad {

  constructor(private _userService: UserService) { }
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.can()
  }

  canActivate(): Observable<boolean> {
    return this.can()
  }
  can() {
    if (this._userService.user?.isAdmin) {
      return of(this._userService.user?.isAdmin ? true : false)
    } else {
      return this._userService.loaded$.pipe(
        filter(loaded => loaded),
        map(() => this._userService.user?.isAdmin ? true : false),
        switchMap((isAdmin) => {
          if (!isAdmin) {
            return from(this._userService.displayLoginDialog()).pipe(
              map(user => typeof user === 'boolean' ? false : !!user.isAdmin)
            );
          } else {
            return of(isAdmin)
          }
        }))
    }
  }
}