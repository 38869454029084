import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class AppInterceptor implements HttpInterceptor {

    constructor() { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // check if there is already an authencation schema in the headers (used for delete / get requests which need credentials)
        if (req.headers.get('Authorization')) {
            return next.handle(req);
        } else {
            // check if a Bearer token is available
            const token = localStorage.getItem("token") || sessionStorage.getItem("token");
            let authReq: HttpRequest<any>;
            if (token) {
                authReq = req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${token}`)
                });
                return next.handle(authReq);
            }
            // else return the request as is
            return next.handle(req.clone());
        }
    }
}