import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-social-share-component',
  templateUrl: './social-share-component.html',
  styleUrls: ['./social-share-component.scss']
})
export class SocialShareComponent implements OnInit {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data :any) { }

  ngOnInit(): void {
  }
}
