import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { I18n } from 'src/app/services/i18n.service';
// import { CreateAccountDialogContentComponent } from '../create-account-dialog-content/create-account-dialog-content.component';
import { LoginDialogContentComponent } from '../login-dialog-content/login-dialog-content.component';

@Component({
  selector: 'app-prompt-login-dialog-content',
  templateUrl: './prompt-login-dialog-content.component.html',
  styleUrls: ['./prompt-login-dialog-content.component.scss']
})
export class PromptLoginDialogContentComponent implements OnInit {

  constructor(private router : Router, public i18n : I18n) { }

  ngOnInit(): void {
  }

//   OnClickCreateAccount() {
// this.matDialog.open(CreateAccountDialogContentComponent)
//   }

  // onClickLogIn() {
  //   this.matDialog.open(LoginDialogContentComponent)
  // }

  goToHomePage() {
    this.router.navigate(['/home']);

  }

  goBack() {
      window.history.back(); 
  }

}
