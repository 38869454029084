<div class="crypto-payment-container">
    <p>
       {{ i18n.get('ethAvailable')}} <br/>
       {{ i18n.get('price')}} : {{orderParams.priceEth}} ETH    
    </p>
    <ng-container *ngIf="!isETHPaimentRequested">
        <button mat-raised-button color="primary" (click)="onETH()">{{i18n.get('payWithEth')}}</button>
    </ng-container>

    <ng-container *ngIf="isETHPaimentRequested">
        <p>
            {{i18n.get('emailNeeded')}}
        </p>
        <form #f="ngForm">
            <mat-form-field>
                <mat-label>{{i18n.get('yourEmailAdress')}}</mat-label>
                <input matInput [(ngModel)]="customerEmail" name="email" required>
            </mat-form-field>

            <button type="button" [disabled]="f.invalid" mat-raised-button color="primary"
                (click)="requestPayement(customerEmail)">{{i18n.get('payWithEth')}}</button>
        </form>
    </ng-container>   
</div>