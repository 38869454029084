import { trigger, state, animate, transition, style, query, animateChild, group } from '@angular/animations';

export const slideInAnimation =
    trigger('routeAnimation', [
        // transition('1 => 99', fade()),
        // transition('1 => 99', slideTo('bottom')),
        // transition('99 => 1', slideTo('top')),
        // transition('99 => *', slideTo('right')),
        // transition('2 => 6', slideTo('bottom') ),
        // transition('6 => 2', slideTo('top')),
        // transition('6 => 1', slideTo('left')),
        // transition('6 => *', slideTo('right')),
        // transition('* => 6', slideTo('right')),        
        // transition('* => 5', slideTo('right')),
        // transition('1 => *', slideTo('right')),
        // transition('5 => *', slideTo('left')),
        // transition('* => 1', slideTo('left')),
        // transition('2 => 3', slideTo('right')),
        // transition('2 => 4', slideTo('right')),
        // transition('3 => 2', slideTo('left')),
        // transition('3 => 4', slideTo('right')),
        // transition('4 => 2', slideTo('left')),
        // transition('4 => 3', slideTo('left')),    
        // transition('* => *', fade()),  
        transition('* => player', shiftUp()),
        transition('player => *', leaveDown()),

    ]);

function slideTo(direction) {
    return [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                // top: 0,
                [direction]: 0,
                width: '100%'
            })
        ]),
        query(':enter', [
            style({ [direction]: '-100%' })
        ]),
        // query(':leave', animateChild()),
        group([
            query(':leave', [
                animate('100ms', style({ [direction]: '100%' }))
            ]),
            query(':enter', [
                animate('100ms', style({ [direction]: '0%' }))
            ])
        ]),
        // query(':enter', animateChild()),
    ]
}

function fade() {
    return [
        // style({ position: 'relative' }),

        query(':enter, :leave', [
            style({
                position: 'absolute',
                //top: 0,
                width: '100%',
                opacity: 1
            })
        ], { optional: true }),
        query(':enter', [
            style({ opacity: '0%' })
        ], { optional: true }),
        group([
            // query(":enter", { optional: true }),
            query(':leave', [
                animate('100ms ease-out', style({ opacity: 0 }))
            ], { optional: true }),
            query(':enter', [
                animate('100ms ease-out', style({ opacity: 1 }))
            ], { optional: true })
        ]),
        // query(':enter', animateChild()),
        // query(':leave', animateChild()), 
    ]

}

function shiftUp() {

    return [
        style({ position: 'relative' }),
        // query(':enter', [
        //     style({
        //         // position: 'relative',
        //         // top: 0,
        //         // [direction]: 0,

        //         // height: '100%'


        //     })
        // ]),
        query(':enter', [
            style({
                position: 'fixed',
                // [direction]: 0,
                bottom: '-100%',
                backgroundColor: 'black',
                width: '100%',
                maxWidth: "1200px"
            })
        ]),
        query(':leave', [
            style({
                // position : 'absolute',
                width: '100%',
                // height : '90%'


            })
        ]),
        group([
            query(':enter', [
                animate('300ms', style({ bottom: '0%' }))
                // animate('300ms', style({ transform: 'translateY(-100%)' }))
            ]),
            query(':enter', animateChild()),
            // query(':leave', animateChild())

        ]),
        // query(':leave', animateChild()),
        // group([
        //     // query(':leave', [
        //     //     animate('100ms', style({ [direction]: '100%' }))
        //     // ]),
        //     query(':enter', [
        //         animate('300ms', style({ [direction]: '0%' }))
        //     ])
        // ]),
        //    query(':enter', animateChild()),

    ]

}

function leaveDown() {
    return [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                // position: 'absolute',
                // top: 0,
                // [direction]: 0,
                width: '100%',
                // height: 'calc(100% - 60px)'


            })
        ]),
        query(':leave', [
            style({
                position: 'fixed',
                maxWidth: "1200px",
                // [direction]: 0,
                bottom: '0%',
                backgroundColor: 'black',
                zIndex: '2'
            })
        ]),
        query(':leave', [
            animate('300ms', style({ bottom: '-100%' }))
            // animate('300ms', style({ transform: 'translateY(-100%)' }))
        ]),
        // query(':leave', animateChild()),
        // group([
        //     // query(':leave', [
        //     //     animate('100ms', style({ [direction]: '100%' }))
        //     // ]),
        //     query(':enter', [
        //         animate('300ms', style({ [direction]: '0%' }))
        //     ])
        // ]),
        //    query(':enter', animateChild()),
        query(':enter', animateChild()),
        query(':leave', animateChild())
    ]

}


export const componentAnimation =
    trigger(
        'fadeAnimation', componentFade()
    );
   
export const playerComponentAnimation = 
trigger(
    'playerComponentAnimation', componentSlideUp()
);

export const componentSlideUpAnimation =

    trigger(
        'componentSlideUpAnimation', componentFade()
    );

function componentFade() {
    return [
        transition(
            ':enter',
            [
                style({ opacity: 0 }),
                animate('3s ease-out',
                    style({ opacity: 1 }))
            ]
        ),
        transition(
            ':leave',
            [
                style({ opacity: 1 }),
                animate('3s ease-in',
                    style({ opacity: 0 }))
            ]
        )
    ]
}

function componentSlideUp() {
    return [
        transition(':enter', [
            style({transform: 'translateY(100%)'}),            
            animate('300ms ease-in', style({ transform: 'translateY(0%)' }))
        ]),

        transition(':leave', [
            style({ transform: 'translateY(0%)' }),
            animate('300ms ease-in', style({ transform: 'translateY(100%)' })),
         
        ]),
        

        // query(':enter', animateChild()),
        // query(':leave', animateChild())
    ]
} 