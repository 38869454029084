import { Injector } from '@angular/core';
import { MasterDataService } from './services/master-data.service';
import { I18n } from './services/i18n.service';
import { catchError } from 'rxjs/operators';
import { MasterData } from './util/interfaces';
import { of } from 'rxjs';

export function initializeI18n(injector: Injector) {
    return () => {
        const i18n: I18n = injector.get(I18n);
        return i18n.getI18nJson$().toPromise()
    }
}

export function initializeMusicData(injector: Injector) {
    return () => {

        const masterDataService = injector.get(MasterDataService);
        return masterDataService.fetchData().pipe(catchError(
            () => {
              return of();
               // handle error
            })).toPromise()
    }
}
