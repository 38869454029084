<mat-list>
    <mat-list-item>
        <span></span>
        <span class="custo-spacer"></span>
        <span><button color="primary" mat-icon-button (click)="sidenav.close()">
                <mat-icon>close</mat-icon>
            </button></span>
    </mat-list-item>
</mat-list>
<mat-action-list>



    <!-- <mat-list-item *ngIf="isAdmin()" (click)="navTo('/admin')">
        <mat-icon matListIcon>admin_panel_settings</mat-icon>
        <div matLine>
            Admin
        </div>
    </mat-list-item> -->

    <mat-list-item (click)="navTo('/studio')">
        <mat-icon class="app-accent" matListIcon>headset</mat-icon>
        <div matLine>
            {{i18n.get('studio')}}
        </div>
    </mat-list-item>

    <!-- <mat-divider></mat-divider> -->

    <mat-list-item (click)="navTo('/copyright')">
        <mat-icon class="app-accent" matListIcon>copyright</mat-icon>
        <div matLine>
            {{i18n.get('copyright')}}
        </div>
    </mat-list-item>

    <mat-list-item (click)="navTo('/contact')">
        <mat-icon class="app-accent" matListIcon>contact_mail</mat-icon>
        <!-- <h3 matLine> </h3> -->
        <div matLine>
            {{i18n.get('contactLink')}}
        </div>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item (click)="navTo('/aboutus')">
        <mat-icon class="app-accent" matListIcon>info</mat-icon>
        <!-- <h3 matLine> </h3> -->
        <div matLine>
            {{i18n.get('aboutUsLink')}}
        </div>
    </mat-list-item>

    <mat-list-item (click)="navTo('/aboutapp')">
        <mat-icon class="app-accent" matListIcon>apps</mat-icon>
        <!-- <h3 matLine> </h3> -->
        <div matLine>
            {{i18n.get('aboutAppLink')}}
        </div>
    </mat-list-item>



    <!-- <mat-list-item *ngIf="!isAuth()" (click)="onClickLogin()">
        <mat-icon matListIcon>login</mat-icon>
        <div matLine>
            {{i18n.get('logIn')}}
        </div>
    </mat-list-item> -->

    <!-- <mat-list-item *ngIf="!isAuth()" (click)="onClickSignUp()">
        <mat-icon matListIcon>person_add</mat-icon>
        <div matLine>
            {{i18n.get('signUp')}}
        </div>
    </mat-list-item> -->

    <!-- <mat-list-item *ngIf="isAuth()" (click)="onClickLogOut()">
        <mat-icon matListIcon>logout</mat-icon>
        <div matLine>
            {{i18n.get('logOut')}}
        </div>
    </mat-list-item> -->

    <!-- <mat-list-item (click)="onClickUpdateAccount()">
        <mat-icon matListIcon>create</mat-icon>
        <div matLine>
            {{i18n.get('updateDeleteAccount')}}
        </div>
    </mat-list-item> -->

    <mat-list-item (click)="onClickSettings()">
        <mat-icon class="app-accent" matListIcon>settings</mat-icon>
        <!-- <h3 matLine> </h3> -->
        <div matLine>
            {{i18n.get('settings')}}
        </div>
    </mat-list-item>

</mat-action-list>