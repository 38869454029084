
<mat-card>
    <mat-card-title>{{i18n.get('thanksViewTitle')}}</mat-card-title>
    <mat-card-subtitle>{{i18n.get('thanksViewSubTitle')}}</mat-card-subtitle>
    <mat-card-content>
        <p>
            {{i18n.get('thanksViewText')}}
        </p>

        <p>
            {{orderId ? i18n.get('orderNumber') : i18n.get('txHash')}} : {{orderId || txHash}} 
        </p>
    </mat-card-content>
</mat-card>