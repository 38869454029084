import { Component, OnInit, Inject } from '@angular/core';
import { LoginData } from '../../util/interfaces';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { I18n } from 'src/app/services/i18n.service';

@Component({
  selector: 'app-update-account-dialog-content',
  templateUrl: './update-account-dialog-content.component.html',
  styleUrls: ['./update-account-dialog-content.component.scss']
})

export class UpdateAccountDialogContentComponent implements OnInit {
  thisnewPassword = "";
  updatePasswordSlide = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: LoginData, public i18n: I18n) { }
  
  public hide = true;
  public hide2 = true;
  public hide3 = true;


  ngOnInit(): void { }

}
