import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { catchError, map, take } from 'rxjs/operators';
import {
  AppSettings,
  MasterData,
  Music,
  MusicSourceObj,
  Category,
  MasterDataCache,
} from '../util/interfaces';
import { I18n } from './i18n.service';
import { StatService } from './stat.service';
import { PopUpService } from './popup.service';
import { _arrayBufferToBase64 } from '../util/utils';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {

  public filteredMusics: Music[];
  public playList: Music[] = [];

  // Master Data
  public categories: Category[] = [];
  public musics: Music[] = [];
  private sources: MusicSourceObj[] = [];
  private appSettings: AppSettings[] = [];

  // Subjects
  public musicDataSubject = new Subject<MasterData>();
  public playListSubject = new Subject<any[]>();
  public randomMusicId: number;
  public randomMusic: Music | undefined;

  public masterDataCache: MasterDataCache;
  picturesPreload: { [name: string]: string } = {}

  constructor(
    private httpClient: HttpClient,
    private statService: StatService,
    private snackBar: MatSnackBar,
    private message: PopUpService,
    public i18n: I18n,
    private router: Router
  ) { }

  public subscribeSearchFieldValue(valueChanges: Observable<string>) {
    // subscribe to change event of sear field
    valueChanges.subscribe((value: string) => {
      this.filteredMusics = this._getfilteredMusics(value).sort(
        this.sortByMusicTitle
      );
    });
  }

  /** APP INITIALIZER
 * Call the API to fetch master data
 */
  public fetchData() {
    return this.httpClient.get(`${environment.API_ENDPOINT}/master-data`, { observe: 'response' }).pipe(
      catchError((res: HttpErrorResponse) => {
        this.router.navigate([`error/${res.status}`]);
        throw res;
      }),
      map(
        (res: HttpResponse<MasterData>) => {
          return this._handleServerAnswer(res.body);
        },
        (error) => {
          console.log('erreur lors de la recuperation des données : ', error);
          return false;
        }
      )
    );
  }

  public emitMasterDataSubject() {
    const data: MasterData = {
      musics: this.musics,
      categories: this.categories,
      sources: this.sources,
      settings: this.appSettings,
    };

    this.musicDataSubject.next(data);
  }

  public emitPlayListSubject() {
    this.playListSubject.next(this.playList);
  }

  /**
   * Store and emit master data
   */
  private _handleServerAnswer(answer: MasterData) {
    // save as property
    this.categories = answer.categories;
    this.musics = answer.musics.map((_music) => {
      // handle data formating for robustness
      return {
        ..._music,
        data: _music.data ? _music.data : {}
      }

    });
    this.sources = answer.sources;
    this.appSettings = answer.settings;
    this.randomMusicId = this._getRandomInt(0, this.musics.length);
    this.randomMusic = this.musics[this.randomMusicId];

    this._preloadImg();

    // emite the subject
    this.emitMasterDataSubject();
  }


  _getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  //getters

  public getMusics(): Music[] {
    return this.musics;
  }

  public getTopMusic(): Music | undefined {
    // let topMusicId = +this.getSettingValue('top_music');
    // return this.getMusicById(topMusicId) || this.musics[0] || {};
    return this.randomMusic;
  }

  public getTopMusicRank(): number {
    // return +this.getSettingValue('top_music_rank');
    return this.randomMusicId + 1;
  }

  public getSettingValue(settingName: string) {
    return this.appSettings?.find((item) => item.name === settingName)?.value;
  }

  public getCategories(): Category[] {
    return this.categories;
  }

  public getCategoryNameById(catId: number) {
    return this.categories?.find((category) => category.id == catId)?.name;
  }

  public getCategoryById(catId: number) {
    return this.categories?.find((category) => category.id === catId);
  }

  public getMusicsByCategoryId(catId: number) {
    return this.musics.filter((music) => music.categoryId == catId);
  }

  public getMusicNameById(musicId: number) {
    const music = this.musics?.find((_music) => _music.id == musicId);
    return music?.title;
  }

  public getMusicById(musicId: number) {
    return this.musics?.find((_music) => _music.id == musicId);
  }

  public getSources(musicId: number) {
    const source = this.sources.find((item) => item.music_id === musicId)
    let fileName = source.src;
    // encode the file name in base64 in order to obfuscate the URL
    fileName = btoa(fileName);
    return `${environment.API_ENDPOINT}/track/${fileName}`;
  }

  /**
   * Get local master data
   * @returns Master data
   */
  public getData(): Observable<MasterData> {
    if (this.musics.length !== 0) {
      return of({
        categories: this.categories,
        musics: this.musics,
        sources: this.sources,
        settings: this.appSettings
      } as MasterData);
    } else return this.musicDataSubject.pipe(take(1));
  }

  public addMusicsIntoPlayList(music: Music, categoryName?: string) {
    if (this.playList.find((item) => music === item) === undefined && music.id != 0) {
      this.playList ? this.playList.push(music) : (this.playList = [music]);
      localStorage.setItem('favorites', JSON.stringify(this.playList));
      if (!categoryName) {
        this.message.showSnackBarTop(music.title + this.i18n.get('addedIntoFavorites'));
      } else {
        this.message.showSnackBarTop(categoryName + this.i18n.get('addedIntoFavorites'))
      }
      this.emitPlayListSubject();
      this.statService.iLike(music.id.toString());
    }
  }

  public clearFavorites() {
    localStorage.removeItem('favorites');
    this.playList = [];
    this.emitPlayListSubject();
  }



  public initFavorites() {
    this.playList = [];
    let jsonPlayList = localStorage.getItem('favorites');
    let playList = JSON.parse(jsonPlayList);
    if (playList) {
      for (let i = 0; i < playList.length; i++) {
        let playListItem = this.musics.find(
          (item) => item.id === playList[i].id
        );
        if (playListItem !== undefined) {
          this.playList.push(playListItem);
        }
      }
      localStorage.setItem('favorites', JSON.stringify(this.playList));
      this.emitPlayListSubject();
    }
  }

  public deleteMusicfromPlaylist(music: Music) {
    for (let i = 0; i < this.playList.length; i++) {
      if (this.playList[i].id === music.id) {
        this.playList.splice(i, 1);
      }
      localStorage.setItem('favorites', JSON.stringify(this.playList));
      this.emitPlayListSubject();
    }
    this.snackBar.open(music.title + this.i18n.get('favoriteDeleted'), '', {
      duration: 3000,
      verticalPosition: 'top',
    });
  }

  //search feature

  private _getfilteredMusics(value: string | null): Music[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.musics.filter((music) =>
      music.title.toLowerCase().includes(filterValue)
    );
  }

  private sortByMusicTitle(a: any, b: any) {
    if (a.title > b.title) {
      return -1;
    } else return 1;
  }

  public getPictureUrlByCategoryId(catId: number): string {
    const category = this.getCategoryById(catId);
    return this.getPictureUrlByPictureName(category.picture);

  }

  public getPictureUrlByCategory(category: Category): string {
    return this.getPictureUrlByPictureName(category.picture);
  }

  public getPictureUrlByMusic(music: Music) {
    return this.getPictureUrlByPictureName(music.data.picture);
  }

  public getDefaultPictureByMusicId(musicId: number) {
    const music = this.getMusicById(musicId);
    const pictureName = music.data.picture ? music.data.picture : this.getCategoryById(music.categoryId)?.picture;
    if (pictureName) {
      return this.getPictureUrlByPictureName(pictureName);

    } else {
      return "";
    }
  }

  public getPictureUrlByPictureName(pictureName: string) {
    const mimeType = this._getMimeType(pictureName);
    return this._getImgPreload(pictureName) ?
      `data:${mimeType};base64,${this._getImgPreload(pictureName)}` :
      `${environment.API_ENDPOINT}/picture/${pictureName}`;
  }

  private _preloadImg() {
    const catImgs = this.categories.map(category => category.picture);
    const catLabeImg = this.categories.map(category => category.picture_title);
    const musicImg = this.musics.map((music) => music.data.picture);
    const imgList = [...catImgs, ...catLabeImg, ...musicImg];

    imgList.forEach((imageName) => {
      if (imageName && !this.picturesPreload[imageName]) {
        this.httpClient.get(`${environment.API_ENDPOINT}/picture/${imageName}`, { responseType: "arraybuffer" }).subscribe((pictures: ArrayBuffer) => {
          const base64 = _arrayBufferToBase64(pictures);
          this.picturesPreload[imageName] = base64;
        })
      }
    })
  }

  _getImgPreload(imgName: string) {
    return this.picturesPreload[imgName];
  }

  _getMimeType(imgName: string) {
    return `image/${imgName?.split('.')[imgName.split('.').length - 1]}`
  }

  public getLabelPictureUrlByCategoryId(catId: number) {
    return `${this.getCategoryById(catId).picture_title}`;
  }
}