import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SocialShareComponent } from '../components/social-share-component/social-share-component';
import { AudioPlayerService } from './audioPlayer.service';
import { MasterDataService } from './master-data.service';


@Injectable()
export class SocialShareService {

  constructor(private bottomSheet: MatBottomSheet) { }
  public openSocialShareCpnt() {
    this.bottomSheet.open(SocialShareComponent, {
      data: { urlToshare: environment.appUrl }
    })
  }

  // private _getUrlToShare() {
  //   if (this.router.url.startsWith('/player')) {
  //     return environment.appUrl + '/#/player/' + this.audioPlayerService.getMusicItem().id.toString();
  //   }
  //   else {
  //     return environment.appUrl + '/#/player/' + this.masterDataService.getTopMusic().id.toString();
  //   }
  // }


}