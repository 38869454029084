import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { I18n } from 'src/app/services/i18n.service';

@Component({
  selector: 'app-language-dialog',
  templateUrl: './language-dialog.component.html',
  styleUrls: ['./language-dialog.component.scss']
})
export class LanguageDialogComponent implements OnInit {
  @Output() onChangeLangEvent = new EventEmitter(true);
  constructor(public i18n : I18n, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  onChangeLang(oEvent: MatSelectChange) {
    this.onChangeLangEvent.emit(oEvent.value); 
  }

}
