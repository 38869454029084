// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { environment as base } from "./environment.default";



export const environment = {
  ...base,
  production: false,
  API_ENDPOINT: "https://api.dev.smalleyesrecords.com", 
  firebase: {
    apiKey: "AIzaSyDKG0ysl0kQmlpjH9SRR_Ifxdm_yYsdlY0",
    authDomain: "smalleyesrecords-1a8c8.firebaseapp.com",
    databaseURL: "https://smalleyesrecords-1a8c8.firebaseio.com",
    projectId: "smalleyesrecords-1a8c8",
    storageBucket: "smalleyesrecords-1a8c8.appspot.com",
    messagingSenderId: "1094301399980",
    appId: "1:1094301399980:web:2cd72d2e70bddab96cd809",
    measurementId: "G-S8WKQHQ748"
  },
  appUrl: "https://www.dev.smalleyesrecords.com",
  VAPID_PUBLIC_KEY: "BCOejWKjjYga5_drpijFNlRKulSmyh75DmmrUDgupwg6hS5-rkVHyUSfP-T-DdNmv9o-QzUq9j-gElC6gvKQP34", //Push notifications VAPID key
  payPalScriptSrc: "https://www.paypal.com/sdk/js?",
  payPalParamsSrc: "currency=EUR&client-id=" + "AdmPx9dk9RULuy1bXkVrUhnlB4tGuv3ZJb-pRNVHduigYdZfpHR_xq7mmz0r49fZeRT9IbdfHKXFexVY", //client Id
  payPalClientId: "AdmPx9dk9RULuy1bXkVrUhnlB4tGuv3ZJb-pRNVHduigYdZfpHR_xq7mmz0r49fZeRT9IbdfHKXFexVY",
  paiement: {
    RECEIVER_ETH_ADDRESS : "0x7C39EEa2a97976A34597AF22bD368Ba42F5aE679"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
