import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { ContactForm } from 'src/app/util/interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { I18n } from 'src/app/services/i18n.service';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-contact-view',
  templateUrl: './contact-view.component.html',
  styleUrls: ['./contact-view.component.scss'],
})
export class ContactViewComponent implements OnInit {
  @ViewChild('f') private form: NgForm;
  public logoTikTokUrl = `${environment.ASSETS}/images/${environment.imagesFileName.logoTikTok}`;
  public logoInstaUrl = `${environment.ASSETS}/images/${environment.imagesFileName.logoIsta}`;
  

  constructor(
    private contactService: ContactService,
    private snackBar: MatSnackBar,
    public i18n: I18n
  ) { }

  ngOnInit(): void { }

  public onSubmitContactForm(form) {
    this.contactService.onSubmitContactForm(form.value).subscribe(
      () => {
        this.snackBar.open(this.i18n.get('messageSent'), '', {
          duration: 3000,
          verticalPosition: 'top',
        });
        this.form.onReset();
      },
      (error) => {
        this.snackBar.open(this.i18n.get('errorOccurs'), '', {
          duration: 3000,
          verticalPosition: 'top',
        });
      }
    );
  }

  public back() {
    window.history.back();
  }
}
