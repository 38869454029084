<app-toolbar [title]="i18n.get('musicDetailsViewTitle')"></app-toolbar>

<div class="picture-container" >
    <img *ngIf="music.data.picture" [src]="musicPictureUrl" class="picture">
    <app-category-thumbnail *ngIf="!music.data.picture" [displayGradient]="false" [category]="category" class="picture">
    </app-category-thumbnail>
</div>

<div class="buttons-container">
    <button mat-raised-button color="primary" [routerLink]="'/order/' + musicId" [disabled]="!music.data.price">{{i18n.get("buy")}}</button>
</div>

<div class="info-container mat-typography">
    <h1>{{music.title}}</h1>

    <h3 *ngIf="music.data.description">
        {{i18n.get('description')}}
    </h3>
    <p>
        {{music.data.description}}
    </p>

    <h3 *ngIf="music.data.tempo || music.data.tone || music.data.duration ">
        {{i18n.get('technicalDetails')}}
    </h3>

    <div class="p-typography">
        <div *ngIf="music.data.tempo">{{i18n.get('tempo')}} : {{music.data.tempo}}</div>
        <div *ngIf="music.data.tone">{{i18n.get('tone')}} : {{music.data.tone}}</div>
        <div *ngIf="music.data.duration">{{i18n.get('totalDuration')}} : {{music.data.duration}}</div>
    </div>

    <h2 *ngIf="music.data.price">
        {{i18n.get("price")}} : {{music.data.price}} EUR
    </h2>

</div>

