import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AudioPlayerService } from 'src/app/services/audioPlayer.service';
import { Router } from '@angular/router';
import { componentAnimation } from 'src/app/util/animation';


@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  animations: [componentAnimation]
})
export class PlayerComponent implements OnInit, OnDestroy {

  public loading: boolean;
  private _subscription = new Subscription();

  constructor(
    private audioPlayerService: AudioPlayerService,
    private router: Router
  ) { }


  ngOnInit(): void {
    this._subscription.add(
      this.audioPlayerService.loadingSubject.subscribe(
        (bLoadingState) => this.loading = bLoadingState
      )
    )
  }

  //-------layout methods--------
  public getDisplayButtonMiniPlayer() {
    if (this.router.url.startsWith('/player')) {
      return false;
    } else return true
  }

  public onCollapse() {
    this.audioPlayerService.isPlayerControlDisplayed = true;

  }

  //------controls methods------
  public onClickPlay() {
    if (!this.audioPlayerService.getPlayingSate()) {
      this.audioPlayerService.play()
    } else {
      this.audioPlayerService.pause();
    }
  }

  public onClickNext() {
    this.audioPlayerService.playNext();
  }

  public getDisabledControlButtons() {
    if (this.audioPlayerService.getState().music.id !== 0) {
      return false;
    } else {
      return true;
    }
  }

  //---------getters-------------
  public getMusicTitle() {
    return this.audioPlayerService.getCurrentMusicTitle();
  }

  public getPlayIcon() {
    return this.audioPlayerService.getPlayingSate() ? "c_pause" : "c_play";
  }

  public isPlayerView() {
    return this.router.url.startsWith('/player') ? true : false
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
