import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Category } from 'src/app/util/interfaces';

@Component({
  selector: 'app-category-button',
  templateUrl: './category-button.component.html',
  styleUrls: ['./category-button.component.scss']
})
export class DossierItemComponent implements OnInit {

  @Input() category: Category;
  constructor(private router: Router) { }
  ngOnInit(): void {
  }

  public onClickCategory() {
    this.router.navigate(['/categories/' + this.category.id]);
  }

}
