import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18n } from 'src/app/services/i18n.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-not-found-view',
  templateUrl: './not-found-view.component.html',
  styleUrls: ['./not-found-view.component.scss']
})
export class NotFoundViewComponent implements OnInit {
  picture404: string;
  errorCode: number;
  displayCode: boolean;
  titleText: string;
  messageText: string
  constructor(public i18n: I18n, private _route: ActivatedRoute) {
    this.picture404 = `${environment.ASSETS}/images/${environment.imagesFileName.logoHeaderLightThemeUrl}`
  }

  ngOnInit(): void {
    this.errorCode = +this._route.snapshot.params['code'];
    switch (this.errorCode) {
      case 404:
        this.titleText = 'error404';
        this.messageText = 'pageNotExists';
        break;
      case 0:
        this.titleText = 'networkError';
        this.messageText = 'networkErrorText';
        break;
      default:
        this.titleText = 'error';
        this.displayCode = true;
        this.messageText = 'networkErrorText';

    }
  }

}
