import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/services/i18n.service';

@Component({
  selector: 'app-private-data-info-dialog-content',
  templateUrl: './private-data-info-dialog-content.component.html',
  styleUrls: ['./private-data-info-dialog-content.component.scss']
})
export class PrivateDataInfoDialogContentComponent implements OnInit {

  constructor(public i18n : I18n) { }

  ngOnInit(): void {
  }

  onAcceptPolicy() {
    localStorage.setItem('policy', 'true');
  }
}
