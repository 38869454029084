<mat-dialog-content>
  <mat-form-field appearance="fill" color="accent">
    <mat-label>{{i18n.get('language')}}</mat-label>
    <mat-select [(value)]="data.lang" (selectionChange)="onChangeLang($event)">
      <mat-option *ngFor="let lang of data.availableLang " [value]="lang.key">
        {{lang.value}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <p class="setting-item" *ngIf="!isNotificationLoading">
    <mat-slide-toggle  #notificationSlide [checked]="isNotificationActivated"
      (toggleChange)="onChangeNotificationState()">
      {{getNotificationStateText()}}
    </mat-slide-toggle>
  </p>
 
  <p class="setting-item spinner" *ngIf="isNotificationLoading">
    <mat-spinner [diameter]="25"></mat-spinner>
  </p>
  
  <p class="setting-item">
    <mat-slide-toggle class="setting-item" [checked]="isDarkTheme" (toggleChange)="onToogleTheme()">
      {{i18n.get('darkTheme')}}
    </mat-slide-toggle>
  </p>

  <!-- <p class="setting-item">
    <mat-slide-toggle class="setting-item" [checked]="isPlaylistNavDisplayed" (toggleChange)="onTooglePlaylistNav()">
      Playlist
    </mat-slide-toggle>
  </p> -->

</mat-dialog-content>

<mat-dialog-actions>
  <button type="button" mat-stroked-button mat-dialog-close="cancel">{{i18n.get('close')}}</button>
</mat-dialog-actions>