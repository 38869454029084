import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MasterDataService } from 'src/app/services/master-data.service';
import { I18n } from 'src/app/services/i18n.service';
import { Music } from 'src/app/util/interfaces';



@Component({
  selector: 'app-single-dossier-view',
  templateUrl: './category-detail-view.component.html',
  styleUrls: ['./category-detail-view.component.scss']
})
export class CategoryDetailViewComponent implements OnInit, OnDestroy {

  public categoryName: string;
  public musicList: Music[];
  private categoryId: number;
  private musicDataSubscription: Subscription;

  constructor(
    public i18n: I18n,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.categoryId = +this.route.snapshot.params['id'];
    this.musicDataSubscription = this.masterDataService.musicDataSubject.subscribe(
      () => {
        this.musicList = this.masterDataService.getMusicsByCategoryId(this.categoryId);
        this.categoryName = this.masterDataService.getCategoryNameById(this.categoryId);
        this.musicList.sort(this._sortByCategorySortNumber);
      }
    );
    this.masterDataService.emitMasterDataSubject();
  }

  public addFolderToPlayList() {
    for (let i = 0; i < this.musicList.length; i++) {
      this.masterDataService.addMusicsIntoPlayList(this.musicList[i], this.categoryName);
    }
  }

  private _sortByCategorySortNumber(a: Music, b: Music) {
    return a.data.categorySort - b.data.categorySort;
  }

  public onClickReturn() {
    window.history.back();
  }

  ngOnDestroy() {
    this.musicDataSubscription.unsubscribe();

  }

}
