import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContactForm, OrderParams } from '../util/interfaces';

@Injectable({ providedIn: 'root' })
export class ContactService {
    constructor(private _httpClient: HttpClient) { }

    public onSubmitContactForm(form: ContactForm) {
        return this._httpClient.post(`${environment.API_ENDPOINT}/contact`, form);
    }

    public confirmePaiement(from: string, orderParams: OrderParams, txHash: string) {
        const form: ContactForm = {
            email: from, 
            firstname : 'no_name_placeholder',
            lastname : 'no_last_name_placeholder',
            text : `Commande de ${orderParams.article} - ${orderParams.category}, price : ${orderParams.priceEth} ETH from ${from}`
        }
        this.onSubmitContactForm(form).subscribe(console.log)
    }
}