<div class="app">
    <mat-sidenav-container style="z-index: 15;" class="app">
        <mat-sidenav [autoFocus]="false" fixedInViewport #sidenav mode="over" position="end" [fixedTopGap]="0" [fixedBottomGap]="0">
            <app-menu [sidenav]="sidenav"></app-menu>
        </mat-sidenav>
        <mat-sidenav-content class="app">
            <div class="header toolbar-shadow" id="header">
                <mat-toolbar class="matToolBar">
                    <img routerLink='/home' *ngIf="getDisplayLogo()" [src]="isDarkTheme ? logoHeaderDarkThemeUrl : logoHeaderLightThemeUrl " height="40px" alt="Logo"
                        class="logobaniere">
                    <span class="custo-spacer"></span>
                    <!-- <mat-search-bar *ngIf="isSearchBarDisplayed()" routerLink="search" routerLinkActive="primary"
                        [formControl]="searchFieldFormControl" #matSearchBar (onFocus)="onFocusSearchBar()"
                        (onClose)="onCloseSearchBar()" [(ngModel)]="searchTerm">
                    </mat-search-bar> -->
                    <button  mat-icon-button color="accent"
                        (click)="onClickToogleTheme()">
                        <mat-icon>{{ isDarkTheme ? "light_mode" : "dark_mode"}}</mat-icon><br />
                    </button>
                    <button mat-icon-button color="accent" (click)="socialShareService.openSocialShareCpnt()"
                        class="icon-button-intermediate smallMarginLeft">
                        <mat-icon class="svg mat-icon40">share</mat-icon>
                    </button>
                </mat-toolbar>
            </div>

            <main class="scroll-overflow body" [@routeAnimation]="prepareRoute(outlet)">
                <div class="viewContainer">
                    <router-outlet #outlet="outlet"></router-outlet>
                </div>
                
                <div class="player-container" [@playerComponentAnimation]>
                    <app-player *ngIf="getDisplayPlayer()"></app-player>
                </div>
                <div [@playerComponentAnimation] *ngIf="isPlayerControlDisplayed()"
                    class="playerControlComponent app-player-control">
                    <app-player-control-view></app-player-control-view>
                </div>

            </main>

            <div class="footer">
                <!-- <mat-divider style="background-color: black;"></mat-divider> -->
                <app-nav-bar color="contrast" [sidenav]="sidenav"></app-nav-bar>
            </div>

        </mat-sidenav-content>
    </mat-sidenav-container>
</div>