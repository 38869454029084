import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface I18nRessource {
    [key: string]: string[]
}

@Injectable()
export class I18n {
    private i18nUrl = environment.ASSETS + environment.i18n;
    private availableLang = ['en', 'fr'];
    private i18n: I18nRessource;


    public lang: string;
    public isPreferedLangIdentified: boolean;
    public langLabels = [
        {
            'value': 'English',
            'key': 'en'
        },
        {
            'value': 'Français',
            'key': 'fr'
        }
    ]

    constructor(private httpClient: HttpClient) {
        if (localStorage.getItem('lang') && this.availableLang.indexOf(localStorage.getItem('lang')) > -1) {
            this.lang = localStorage.getItem('lang');
            this.isPreferedLangIdentified = true;
        } else {
            try {
                let navigatorLang = navigator.language;

                //check if navigator lang is in available languages
                for (let i = 0; i < this.availableLang.length; i++) {
                    if (navigatorLang.indexOf(this.availableLang[i]) > -1) {
                        this.lang = this.availableLang[i];
                        this.isPreferedLangIdentified = true
                        break;
                    }
                }

                if (!this.isPreferedLangIdentified) {
                    this.lang = 'en';
                    this.isPreferedLangIdentified = false;
                }
            } catch (err) {
                this.lang = 'en';
                this.isPreferedLangIdentified = false;
            }
        }
    }


    /**
     * get translation file - called by app initializer
     */
    public getI18nJson$() {

        return this.httpClient.get(this.i18nUrl).pipe(map(
            (i18n: I18nRessource) => {
                this.i18n = i18n;
                return true;
            },
            err => {
                console.log(err)
            }
        ));
    }

    /**
     * Set and record the preffered language
     */
    public setLang(sLang: string) {
        this.lang = sLang;
        localStorage.setItem('lang', this.lang)
    }

    /**
     * Return the translated text
     */
    public get(sKey: string) {
        if (this.i18n[sKey] && this.i18n[sKey][this.lang]) {
            return this.i18n[sKey][this.lang];
        } else {
            if (!this.i18n[sKey]) {
                console.error("i18n error : sKey '" + sKey + "' is not defined");
                return sKey;
            } else if (!this.i18n[sKey][this.lang]) {
                console.error("i18n error : lang '" + this.lang + "' for key '" + sKey + "' is not defined")
                return sKey;
            }
        }
    }
}
