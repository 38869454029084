
<div>

    <mat-card>
    <mat-card-content>
        <mat-action-list>
        <app-music-list-item *ngFor="let music of masterDataService.filteredMusics" [music]="music">
        </app-music-list-item>
    </mat-action-list>

    </mat-card-content>

    </mat-card>

</div>
