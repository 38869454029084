import { Component, OnInit, ViewChild } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { I18n } from 'src/app/services/i18n.service';
import { PopUpService } from 'src/app/services/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-app-view',
  templateUrl: './about-app-view.component.html',
  styleUrls: ['./about-app-view.component.scss']
})
export class AboutAppViewComponent implements OnInit {
  public appUrl: string;
  public isLoading: boolean;
  constructor(public i18n : I18n, private popupService : PopUpService, private swUpdate: SwUpdate) { }
  
  ngOnInit(): void {
    this.appUrl = location.href.split('#')[0];
  }
  
  public onClickMentionsLegalesButton() {
    this.popupService.displayDialog("PrivateDataInfoDialogContentComponent");
  }
  // hack to reload the app in refreshing the cache
  public async reLoad() {
    this.isLoading = true;
    await this.swUpdate.checkForUpdate();    
    window.location.reload();
  }
}
