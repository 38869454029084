import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './services/auth-guard.service';
import { PlayListComponent } from './views/favorites-view/play-list.component';
import { ContactViewComponent } from './views/contact-view/contact-view.component';
import { AboutAppViewComponent } from './views/about-app-view/about-app-view.component';
import { NotFoundViewComponent } from './views/not-found-view/not-found-view.component';
import { OrderComponent } from './views/order/order.component';
import { ThanksViewComponent } from './views/thanks-view/thanks-view.component';
import { PlayerControlViewComponent } from './views/player-control-view/player-control-view.component';
import { SearchViewComponent } from './views/search-view/search-view.component';
import { MasterDataService } from './services/master-data.service';
import { HomeViewComponent } from './views/home-view/home-view.component';
import { CategoriesViewComponent } from './views/categories-view/categories-view.component';
import { CategoryDetailViewComponent } from './views/category-detail-view/category-detail-view.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { MusicDetailsViewComponent } from './views/music-details-view/music-details-view.component';
import { CopyrightViewComponent } from './views/copyright-view/copyright-view.component';



const routes: Routes = [
  { path: 'music/:id', component: MusicDetailsViewComponent },
  { path: 'categories', component: CategoriesViewComponent, data: { animation: '2' } },
  { path: 'categories/:id', component: CategoryDetailViewComponent, data: { animation: '6' } },
  { path: 'favorites', component: PlayListComponent, data: { animation: '4' } },
  { path: 'home', component: HomeViewComponent, pathMatch: 'full', data: { animation: '1' } },
  { path: 'contact', component: ContactViewComponent, data: { animation: '99' } },
  { path: 'aboutus', component: AboutUsComponent, data: { animation: '99' } },
  { path: 'aboutapp', component: AboutAppViewComponent, data: { animation: '99' } },
  { path: 'studio', loadChildren: () => import('./views/studio-module/studio.module').then(m => m.AppStudioModule) },
  { path: 'copyright', component: CopyrightViewComponent },
  { path: 'order/:id', component: OrderComponent },
  { path: 'thanks/:id', component: ThanksViewComponent },
  { path: 'thanks/eth/:tx', component: ThanksViewComponent },
  { path: 'search', component: SearchViewComponent },
  { path: 'player', component: PlayerControlViewComponent, data: { animation: 'player' } },
  { path: 'player/:id', component: PlayerControlViewComponent, data: { animation: 'player' } },
  { path: 'admin', canLoad: [AdminGuard], loadChildren: () => import('./Modules/admin-module/admin.module').then(m => m.AppAdminModule) },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'error/:code', component: NotFoundViewComponent },
  { path: '**', redirectTo: 'error/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

