<h2 mat-dialog-title>{{i18n.get('connexionPopupTitle')}}</h2>

<form #f="ngForm">
    <mat-dialog-content>

        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('emailAdress')}}</mat-label>
                <input matInput [placeholder]="i18n.get('emailAdress')" name="email" ngModel required>

            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{i18n.get('password')}}</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" name="password" ngModel required>
                <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
        </p>
        <p>
            <mat-checkbox  name="stayConnected" [ngModel]="data.stayConnected">
                {{i18n.get('stayConnected')}}</mat-checkbox>
        </p>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button type="button" mat-button mat-dialog-close="cancel">{{i18n.get('cancel')}}</button>
        <button type="submit" mat-button [mat-dialog-close]="f.value"
            [disabled]="f.invalid">{{i18n.get('validate')}}</button> <br>
        <button type="button" mat-button mat-dialog-close="createAccount">{{i18n.get('signUp')}}</button>
    </mat-dialog-actions>


</form>