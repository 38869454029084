<h2 mat-dialog-title>{{i18n.get('chooseLanguagePopupTitle')}}</h2>
 <mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>{{i18n.get('language')}}</mat-label>
        <mat-select [(value)]="data.lang" (selectionChange)="onChangeLang($event)">
          <mat-option *ngFor="let lang of data.availableLang " [value]="lang.key">
            {{lang.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close >{{i18n.get('ok')}}</button>
</mat-dialog-actions>