import { Component, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { I18n } from 'src/app/services/i18n.service';
import { LoginData } from 'src/app/util/interfaces';



@Component({
  selector: 'app-login-dialog-content',
  templateUrl: './login-dialog-content.component.html',
  styleUrls: ['./login-dialog-content.component.scss']
})

export class LoginDialogContentComponent implements OnInit {

  public hide = true;
  public stayConnected = true;

  constructor(public i18n : I18n, @Inject(MAT_DIALOG_DATA) public data: LoginData) { }

  ngOnInit(): void {    
  }
}
