import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { SettingDialogData } from 'src/app/util/interfaces';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { I18n } from 'src/app/services/i18n.service';
import { MatSelectChange } from '@angular/material/select';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-notification-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SettingsDialogComponent implements OnInit {
  isNotificationActivated: boolean;
  isNotificationLoading: boolean;
  isDarkTheme: boolean;
  isPlaylistNavDisplayed: boolean;

  @ViewChild('notificationSlide') notificationSlide: MatSlideToggle;
  @Output() onToogleSlide = new EventEmitter(true);
  // @Output() onClickUpdateAccount = new EventEmitter(true);
  // @Output() onClickMentionsLegales = new EventEmitter(true);
  @Output() onChangeLangEvent = new EventEmitter(true);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SettingDialogData,
    public i18n: I18n,
    private themeService: ThemeService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.data.isNotificationActivated.subscribe((isNotifActive) => {
      this.isNotificationActivated = isNotifActive;
      this.cd.detectChanges();
    })
    this.data.isDarkTheme.subscribe((isDarkTheme) => {
      this.isDarkTheme = isDarkTheme;
      this.cd.detectChanges();

    })
    this.data.bLoader.subscribe((isLoading) => {
      this.isNotificationLoading = isLoading;
      this.cd.detectChanges();

    })

    this.isPlaylistNavDisplayed = JSON.parse(localStorage.getItem('playlistNav'))
  }

  public onChangeNotificationState() {
    this.onToogleSlide.emit(this.notificationSlide.checked);
  }

  public getNotificationStateText() {
    return this.isNotificationActivated === true
      ? this.i18n.get('notificationEnabled')
      : this.i18n.get('notificationdisabled');
  }

  public onChangeLang(oEvent: MatSelectChange) {
    this.onChangeLangEvent.emit(oEvent.value);
  }

  public onToogleTheme() {
    this.themeService.toogleTheme();
  }

  // public onTooglePlaylistNav() {
  //   let isPlaylistNav = this.isPlaylistNavDisplayed ? false : true;
  //   localStorage.setItem('playlistNav', JSON.stringify(isPlaylistNav));
  //   this.isPlaylistNavDisplayed = isPlaylistNav;
  // }


}
