import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { I18n } from 'src/app/services/i18n.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input()
  public sidenav: MatSidenav;
  
  constructor(public i18n: I18n,
     private settingsService : NotificationService, 
     private userService: UserService, 
     private router: Router) { }

  ngOnInit(): void {
  }

  public getUserName() {
    return this.userService.isAuth ? this.userService.user?.firstname + ' ' + this.userService.user.lastname : this.i18n.get('guest')
  }

  public navTo(url) {
    this.sidenav.close();
    this.router.navigate([url])
  }

  public onClickLogin() {
    this.userService.displayLoginDialog()
  }

  public onClickLogOut() {
    this.userService.logOut()
  }

  public onClickSignUp() {
    this.userService.displayCreateUserDialog().subscribe()
  }
 
  public onClickSettings() {
    this.settingsService.displaySettingsPopup()
  }

  public onClickUpdateAccount() {
    this.userService.displayUpdateUserDialog()
  }

  public isAdmin() {
    return this.userService.user?.isAdmin
  }

  public isAuth() {
    return this.userService.isAuth;
  }
}
