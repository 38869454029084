import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { AudioPlayerService } from 'src/app/services/audioPlayer.service';
import { I18n } from 'src/app/services/i18n.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { StatService } from 'src/app/services/stat.service';
import { Music } from 'src/app/util/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-music-list-item',
  templateUrl: './music-list-item.component.html',
  styleUrls: ['./music-list-item.component.scss'],
})
export class MusicListItemComponent implements OnInit {
  @Input() music: Music;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  @Output() newItemPressed = new EventEmitter<void>();
  @Output() onOrder = new EventEmitter();
  public pictureUrl: string;
  constructor(
    private audioPlayerService: AudioPlayerService,
    private masterDataService: MasterDataService,
    private statService: StatService,
    private router: Router,
    private route: ActivatedRoute,
    public i18n: I18n
  ) { }

  ngOnInit(): void {
    this.pictureUrl = this.masterDataService.getDefaultPictureByMusicId(this.music.id);

  }

  public getName() {
    return this.music['title'];
  }

  public getCategoryName() {
    return this.masterDataService.getCategoryNameById(this.music.categoryId);
  }

  public getCategoryPicture() {
    return this.masterDataService.getCategoryById(this.music.categoryId).picture;
  }

  public onClickListItem() {
    let list: string;
    const route = this.route.snapshot.url[0].path;
    switch (route) {
      case "categories":
        list = route;
        break;
      case "favorites":
        list = route;
        break;
      default:
        list = "all"
        break;
    }
    // const category = document.location.href.split('/')[
    //   document.location.href.split('/').length - 1
    // ];
    this.audioPlayerService.playIt(this.music, list);
    this.newItemPressed.next();
  }

  public onCLickFavorite(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    if (this._isFavorite(this.music)) {
      this.masterDataService.deleteMusicfromPlaylist(this.music);
    } else {
      this.masterDataService.addMusicsIntoPlayList(this.music);
    }
  }

  public isActive() {
    if (this.music.id == this.audioPlayerService.getState().music.id) {
      return true;
    }
  }

  public getFavoriteIcon() {
    return this._isFavorite(this.music) ? 'favorite' : 'favorite_border';
  }

  public getFavoriteText() {
    const textKey = this._isFavorite(this.music) ? 'removeFavorite' : 'addFavorite';
    return this.i18n.get(textKey);
  }

  private _isFavorite(music) {
    let favoriteList = this.masterDataService.playList;
    if (favoriteList.find((item) => item.id === music.id) === undefined) {
      return false;
    } else {
      return true;
    }
  }

  public onClickILike() {
    this.statService.iLike(this.music.id.toString());
  }

  public order(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.router.navigate(['order/' + this.music.id]);
    this.onOrder.next(this.music);
  }
}
