import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AudioPlayerService } from 'src/app/services/audioPlayer.service';
import { I18n } from 'src/app/services/i18n.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, AfterViewInit {
  @ViewChild('main') main: ElementRef<HTMLElement>;
  @Input() public sidenav: MatSidenav;
  @Input() private color: 'primary' | 'accent' | 'contrast';
  constructor(
    public i18n: I18n,
    private router: Router,
    private audioService: AudioPlayerService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.main.nativeElement.classList.add(`app-${this.color}`);
  }

  public navTo(string: string) {
    this.audioService.isPlayerControlDisplayed = false;
    this.router.navigate([string]);
  }

  isPlaylistDisplayed() {
    return true;
    // return JSON.parse(localStorage.getItem('playlistNav'));
  }
}
