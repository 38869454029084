import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContactService } from 'src/app/services/contact.service';
import { I18n } from 'src/app/services/i18n.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { PopUpService } from 'src/app/services/popup.service';
import { OrderParams } from 'src/app/util/interfaces';
import { scrollDown } from 'src/app/util/utils';
import { environment } from 'src/environments/environment';
import { Ethereumish } from './ethereum.types';
// import Web3 from './web3.js';

declare global {
  interface Window {
    ethereum: Ethereumish;
  }
}

@Component({
  selector: 'app-web3',
  templateUrl: './web3.component.html',
  styleUrls: ['./web3.component.scss']
})
export class Web3Component implements OnInit, AfterViewInit {
  readonly DEFAULT_PRICE_ETH = 0.05;
  @Input('orderParams') orderParams: OrderParams

  public isETHPaimentRequested: boolean = false;
  public customerEmail: string;
  public txHash: string;

  private _receiverEthAddress: string;

  constructor(private messageSrv: PopUpService, public i18n: I18n,
    private contactSrv: ContactService,
    private _router: Router,
    private _masterData: MasterDataService) {
  }

  ngOnInit(): void {
    this.orderParams.priceEth = this.orderParams.priceEth || this.DEFAULT_PRICE_ETH;
    this._receiverEthAddress = this._masterData.getSettingValue('eth_address');
  }

  ngAfterViewInit(): void {
    scrollDown()
  }

  /**
   * On click on pay with eth button
   */
  onETH() {
    this.isETHPaimentRequested = true;
    setTimeout(() => scrollDown(), 0)
  }

  requestPayement(email: string) {
    if (window.ethereum) {
      const eth = window.ethereum;
      eth.request({ method: 'eth_requestAccounts' }).then((adresses) => {
        const sender = adresses[0]
        eth.request(
          {
            method: 'eth_sendTransaction',
            params: [
              {
                to: this._receiverEthAddress,
                from: sender,
                value: this.fromEth(this.orderParams.priceEth),
                // gas: new Number(90000).toString(16),
                // gasPrice: new Number(20000000000).toString(16),
                // data: this.convertToHex(email)
              }
            ]
          }).then((txHash) => {
            this._router.navigate([`/thanks/eth/${txHash}`]);
            this.txHash = txHash
            this.contactSrv.confirmePaiement(email, this.orderParams, txHash)
          })
      })
    } else {
      const deepLink = `https://metamask.app.link/dapp/${environment.appUrl.split('//')[1]}/#${this._router.url}`
      console.log(deepLink)
      console.log(environment.appUrl)
      window.open(deepLink, "_blank");
      this.messageSrv.showError(this.i18n.get('noWalletEth'));
    }
  }

  /**
   * Convert eth amount into encoded value complient with the ethereum interfac
   * @param ethQty 
   * @returns 
   */
  fromEth(ethQty: number) {
    return new Number(ethQty * 1000000000000000000).toString(16)
  }

  convertToHex(str) {
    var hex = '';
    for (var i = 0; i < str.length; i++) {
      hex += '' + str.charCodeAt(i).toString(16);
    }
    return hex;
  }


  /** experimental */
  // web3: Web3
  // async _initWeb3() {
  // this.web3 = new Web3('http://localhost:8000')
  // this.web3.eth.getAccounts().then(console.log)
  // // test local wallet
  // // this.web3.eth.getBalance("0x4D1cA732402320C3695b8346a2A02e2E79aCbca1").then(console.log)
  // this.web3.eth.sendTransaction({
  //   from: "0x6539DbD962e9571478aAa75fb08340a6C5eaB751",
  //   to: "0xF8E06d98d56497B236481214642042A8ee6392C0",
  //   value: 9999159999999159970,
  //   gas: 21000,
  //   gasPrice : 40,

  // }).then(console.log).catch(console.error)
  // }
}
