export function getAuthToken(): string {
  return localStorage.getItem("token") || sessionStorage.getItem("token");
}

export function _arrayBufferToBase64(buffer: ArrayBuffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function scrollDown() {
  const viewContainer = document.getElementsByTagName('main')[0]
  viewContainer.scrollTo(0, viewContainer.scrollHeight);
}