import { NgModule } from "@angular/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatToolbarModule } from "@angular/material/toolbar";

@NgModule({
    imports: [
        MatSliderModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatListModule,
        MatDialogModule,
        MatGridListModule,
        MatFormFieldModule,
        MatCardModule,
        MatToolbarModule,
        MatExpansionModule,
        MatRippleModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatBottomSheetModule,
        MatSidenavModule,
        MatInputModule,
        MatProgressSpinnerModule,

    ],
    exports: [
        MatSliderModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatListModule,
        MatDialogModule,
        MatGridListModule,
        MatFormFieldModule,
        MatCardModule,
        MatToolbarModule,
        MatExpansionModule,
        MatRippleModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatBottomSheetModule,
        MatSidenavModule,
        MatInputModule,
        MatProgressSpinnerModule,
    ]
})
export class AppMaterialModule {

}