import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AudioPlayerService } from 'src/app/services/audioPlayer.service';
import { I18n } from 'src/app/services/i18n.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { SocialShareService } from 'src/app/services/socialShare.service';
import { componentAnimation } from 'src/app/util/animation';
import { Category, Music } from 'src/app/util/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-player-control-view',
  templateUrl: './player-control-view.component.html',
  styleUrls: ['./player-control-view.component.scss'],
  animations: [componentAnimation]
})
export class PlayerControlViewComponent implements OnInit, OnDestroy {
  private musicId: number; // url param
  public musicItem: any; // retrieved form audioPlayer service (current music)
  private category: Category;

  private currentTime = 0;
  public sliderValue: number;
  private duration: number;

  private canSliderChangeValueOnCurrentTimeUpdate = true; //bux fix slider
  private sliderChangeValue = null;
  public loading: boolean;

  private _subscription = new Subscription();

  constructor(public socialShareService: SocialShareService,
    public i18n: I18n,
    private route: ActivatedRoute,
    public audioPlayerService: AudioPlayerService,
    private masterDataService: MasterDataService,
    private router: Router) {
    document.addEventListener("mouseup", this.onTouchEnd.bind(this));
  }
 

  ngOnInit(): void {
    // case music defined by URL
    if (this.route.snapshot.params.id) {
      let sameMusicInPlaying = false;
      if (this.audioPlayerService.getState().music.id === this.route.snapshot.params['id']) {
        sameMusicInPlaying = true;
      } else {
        this.musicId = this.route.snapshot.params['id'];
        // this.musicId ? this.musicId : this.audioPlayerService.getMusicItem().id
      }
      if (this.musicId && !sameMusicInPlaying) {
        let musicObj = this.masterDataService.getMusicById(this.musicId)
        if (musicObj) {
          this.audioPlayerService.playIt(musicObj, "liste")
        } else {
          this.router.navigate(['/notfound'])
          return;
        }
      }
      if (this.audioPlayerService.getCurrentMusicTitle() === "") {
        window.history.back();
        return;
      }
    }

    this.musicItem = this.audioPlayerService.getMusicItem();
    this.category = this.masterDataService.getCategoryById(this.musicItem.categoryId);
    this.currentTime = this.audioPlayerService.currentTime;
    this.duration = this.audioPlayerService.duration;
    this.sliderValue = this.currentTime;

    this._subscription.add(this.audioPlayerService.currentTimeSubject.subscribe(
      (currentTime: number) => {
        this.currentTime = currentTime;
        if (this.canSliderChangeValueOnCurrentTimeUpdate) {
          this.sliderValue = this.currentTime;
        }
      }
    ));

    this._subscription.add(this.audioPlayerService.durationSubject.subscribe(
      (duration: number) => {
        this.duration = duration;
      }
    ))

    this._subscription.add(this.audioPlayerService.loadingSubject.subscribe(
      (bLoadingState) => this.loading = bLoadingState
    ))

    this._subscription.add(this.audioPlayerService.stateSubject.subscribe(
      (oState) => {
        this.musicItem = this.audioPlayerService.getMusicItem();
        this.category = this.masterDataService.getCategoryById(this.musicItem.categoryId);

      }
    ))
    this.audioPlayerService.emitStateSubject();

  }

  public onClickPlay() {
    if (!this.audioPlayerService.getPlayingSate()) {
      this.audioPlayerService.play()
    } else {
      this.audioPlayerService.pause();
    }
  }

  public onClickNext() {
    this.audioPlayerService.playNext();
  }

 public onClickPrevious() {
    this.audioPlayerService.playPrevious();
  }

  public onClickRepeat() {
    this.audioPlayerService.repeat();
  }

  public getDisabledControlButtons() {
    if (this.audioPlayerService.getState().music.id !== 0) {
      return false;
    } else {
      return true;
    }
  }

  public getCategory() {
    return this.category;
  }

  //---------getters-------------
  public getMusicTitle() {
    return this.audioPlayerService.getCurrentMusicTitle();
  }

  public getMusicPictureUrl(music: Music) {
    return this.masterDataService.getPictureUrlByMusic(music);
  }

  public getPlayIcon() {
    if (!this.audioPlayerService.getPlayingSate()) {
      return "c_play";
    } else return "c_pause"
  }

  public getRepeatIcon() {
    let repeatState = this.audioPlayerService.getState().repeat;
    if (repeatState === "one") {
      return "c_loop"
    } else if (repeatState === "no") {
      return "c_loop";
    }
  }

  public isRepeatActivated() {
    let repeatState = this.audioPlayerService.getState().repeat;
    if (repeatState === "one") {
      return true;
    } else if (repeatState === "no") {
      return false;;
    }
  }

  public getCurrentTime() {
    if (this.currentTime !== undefined)
      return this.formatTime(this.currentTime);
  }

  public getDurationFormated() {
    return this.formatTime(this.getDuration())
  }

  public getDuration() {
    return this.duration;
  }

  public getTopCategoryUrl() {
    return '/categories/' + this.masterDataService.getTopMusic().categoryId
  }

  //--------Slider------------------
  public formatTime(seconds: number) {
    return [
      Math.trunc(seconds / 60 % 60),
      Math.trunc(seconds % 60)
    ]
      .join(":")
      .replace(/\b(\d)\b/g, "0$1")
  }

  public onChangeSliderValue(value: number) {
    this.sliderChangeValue = value;
  }

  public onMoovingThumb(event: MatSliderChange) {
    this.sliderChangeValue = event.value;
    this.canSliderChangeValueOnCurrentTimeUpdate = false;
  }

  public onTouchEnd() {
    if (this.sliderChangeValue !== null && this.canSliderChangeValueOnCurrentTimeUpdate === false) {
      this.audioPlayerService.onSeek(this.sliderChangeValue);
      this.canSliderChangeValueOnCurrentTimeUpdate = true;
      this.sliderChangeValue = null;
    }
  }

  public setCurrentTimeSlider() {
    return this.sliderValue;
  }

  //--------Favorite----------
  public handleFavorite() {
    const favoriteList = this.masterDataService.playList
    const musicItem = this.audioPlayerService.getState().music;

    if (favoriteList.find(item => item.id === musicItem.id) === undefined) {
      this.masterDataService.addMusicsIntoPlayList(musicItem);
    } else {
      this.masterDataService.deleteMusicfromPlaylist(musicItem);
    }

  }

  public getFavoriteIcon() {
    // if (this.userService.isAuth) {
    const favoriteList = this.masterDataService.playList
    const musicItem = this.audioPlayerService.getState().music;
    if (favoriteList.find(item => item.id === musicItem.id) === undefined) {
      return "c_favorite_empty";
    } else {
      return "c_favorite_full";
    }

  }

  //------- Navigation ------------

  public onClickCartButton() {
    this.router.navigate(['/order/' + this.audioPlayerService.getMusicItem().id]);
    this.audioPlayerService.isPlayerControlDisplayed = false;
  }

  public onClickReturn() {
    if (this.musicId) { //means the view has been reached by url, so not history yet
      this.router.navigate(['/home'])
    } else { // navigate to the previous view
      this.audioPlayerService.isPlayerControlDisplayed = false;
    }
  }

  public leavePlayerControl() {
    this.audioPlayerService.isPlayerControlDisplayed = false;
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
