import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ThemeService {
  private _isDarkTheme: boolean;
  private _isDarkTheme$: BehaviorSubject<boolean>

  constructor() {
    this._isDarkTheme$ = new BehaviorSubject(this._isDarkTheme);
    switch (localStorage.getItem('theme')) {
      case 'dark':
        this.setTheme(true);
        break;
      case 'light':
        this.setTheme(false);
        break;
      default:
        this._isDarkTheme = this.isDarktheme();
        this._isDarkTheme$.next(this._isDarkTheme);
    }
  }

  public isDarktheme() {
    return document
      .getElementsByTagName('body')
      .item(0)
      .classList.contains('dark-theme');;
  }

  public isDarkTheme$() {
    return this._isDarkTheme$;
  }

  public setTheme(setDarkTheme: boolean) {
    if (!setDarkTheme) {
      document.getElementsByTagName('body')[0].classList.remove('dark-theme');
      localStorage.setItem('theme', 'light');
    } else {
      document.getElementsByTagName('body')[0].classList.add('dark-theme');
      localStorage.setItem('theme', 'dark');
    }
    this._isDarkTheme = this.isDarktheme();
    this._isDarkTheme$.next(this._isDarkTheme);
  }

  public toogleTheme() {
    this.setTheme(!this.isDarktheme());
  }
}
