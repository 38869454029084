import { Injectable, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PopUpService } from './popup.service';
import { mergeMap, map, filter, take } from 'rxjs/operators';
import { UserService } from './user.service';
import { I18n } from './i18n.service';
import { ThemeService } from "./theme.service";
import { SettingDialogData } from "../util/interfaces";
import { MatDialog } from "@angular/material/dialog";
import { SettingsDialogComponent } from "../fragments/settings-dialog/settings-dialog.component";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";


@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    // http destinations
    // properties
    isNotificationActivated: boolean;
    isNotificationActivated$: BehaviorSubject<boolean>

    isNotificationProcessLoading$: BehaviorSubject<boolean>
    isNotificationProcessLoading: boolean; // set to true when the service is processing request and false when nothing is on going



    serverKey: string;
    notificationLog: string;
    lastNotificationSent: string;

    constructor(
        private http: HttpClient,
        private angularFireMessaging: AngularFireMessaging,
        private popupService: PopUpService,
        public i18n: I18n,
        private userService: UserService,
        private ngZone: NgZone,
        private themeService: ThemeService,
        private _dialog: MatDialog

    ) {
        this.isNotificationProcessLoading$ = new BehaviorSubject(false);
        this.isNotificationActivated$ = new BehaviorSubject(this.isNotificationActivated);

        if (JSON.parse(localStorage.getItem('pushNotif'))) {
            this._checkAndSetSubscriptionState().subscribe(
                (isNotificationActivated) => {
                    this._setNotifActive(isNotificationActivated);
                    if (isNotificationActivated) {
                        // update DB in case of token refreshed
                        this.angularFireMessaging.tokenChanges.subscribe(
                            (token) => this._addPushSubscriberToDB(token),
                            err => console.log(err)
                        )
                    }
                },
                err => console.log(err)
            );     // set value for isNotificationActivated
        } else {
            this._setNotifActive(false);
        }

    }

    public sendNotification(title: string, body: string, linkOption: number) {
        const appUrl = environment.appUrl;
        let clickActionUrl: string
        if (linkOption) {
            clickActionUrl = appUrl + '/#/player/' + linkOption;
        } else {
            clickActionUrl = appUrl;
        }

        let apiPayload = {
            title: title,
            body: body,
            icon: "assets/icons/Ico-192.png",
            clickAction: clickActionUrl
        }

        return this.http.post(`${environment.API_ENDPOINT}/push/notification`, apiPayload).pipe(map(
            (res: any) => {
                if (res.success > 0) { // number of push messages sent successfully
                    // this.popupService.showSnackBarBottom("Notification envoyée à " + res.success + " destinataires, " + res.failure + " utilisateurs se sont désabonnés");
                    this.notificationLog = "Notification envoyée à " + res.success + " destinataires, " + res.failure + " utilisateurs se sont désabonnés"
                    this.lastNotificationSent = "Titre : " + title + "\n, Message : " + body;
                    return "success";
                } else {
                    this.popupService.showSnackBarTop("Aucune notification envoyée");
                    return "null";
                }
            },
            (err) => {
                if (err.error.message === "no user registered")
                    this.popupService.showSnackBarTop("Aucun utilisateur enregistré");
            }
        ));
    }

    public listenForeGroundPushMessages() {
        this.isNotificationActivated$.pipe(
            filter(isActive => isActive !== undefined),
            take(1)
        ).subscribe(
            (isActive) => {
                if (isActive) {
                    this.angularFireMessaging.messages.subscribe(
                        (message: any) => {
                            this.popupService.showSnackBarTop(message.notification.title + ' - ' + message.notification.body)
                        },
                        (err) => {
                            //alternate logic for safari ...
                            console.log("Notification API not implemented", err);
                        }
                    );
                }
            },
            err => console.log(err)
        );
    }

    public displaySettingsPopup() {
        let data: SettingDialogData = {
            bLoader: this.isNotificationProcessLoading$,
            isNotificationActivated: this.isNotificationActivated$,
            lang: this.i18n.lang,
            availableLang: this.i18n.langLabels,
            isDarkTheme: this.themeService.isDarkTheme$()
        }
        // open popup and subscribe to event
        const dialogRef = this._dialog.open(SettingsDialogComponent, { data: data })
        // const settingPopup = this.popupService.displayDialog("SettingsDialogComponent", data)

        dialogRef.componentInstance.onToogleSlide.subscribe(isChecked => this._changeSubscriptionState());
        dialogRef.componentInstance.onChangeLangEvent.subscribe((lang) => this.i18n.setLang(lang)
        )
    }

    private _checkAndSetSubscriptionState() {
        if (this.isNotificationActivated === undefined) {
            try { // bug fix - handle error when Notification API is not implemented (safari and chrome on iOS)
                if (Notification.permission === "granted") {
                    return this.angularFireMessaging.getToken.pipe(map(
                        (res) => {
                            if (typeof (res) === "string") {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        err => false
                    ));
                } else {
                    return of(false)
                }

            } catch (err) {
                console.log(err)
                return of(false);
            }

        } else {
            return of(this.isNotificationActivated)
        }
    }

    private _requestPermission() {
        // request firebase token to identifie the device
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                this.ngZone.run(() => {
                    // console.log("token requested : ", token);
                    //record token in database
                    this._addPushSubscriberToDB(token).subscribe();
                    this._setNotifActive(true);
                    this._setNotifLoading(false);
                });
            },
            (err) => {
                this.ngZone.run(() => {
                    console.log('Unable to get permission to notify.', err);
                    this._setNotifActive(false);
                    this._setNotifLoading(false);

                    try { // bug fix - handle error when Notification API is not implemented (safari and chrome on iOS)
                        if (Notification.permission === "denied") {
                            this.popupService.showSnackBarTop(this.i18n.get('notificationLockedBySystem'));
                        } else {
                            this.popupService.showSnackBarTop(this.i18n.get('notificationTechnicalError'));
                        }
                    } catch (err) {
                        this.popupService.showSnackBarTop(this.i18n.get('notificationNotSupported'));
                    }
                });
            }
        );
    }

    private _addPushSubscriberToDB(token: string): Observable<any> {
        // database save of the push subscription object
        return this.http.post(`${environment.API_ENDPOINT}/push/subscriber`, { token: token });
    }

    private _changeSubscriptionState() {
        this._setNotifLoading(true);
        this.isNotificationActivated ? this._deleteToken() : this._requestPermission();

    }

    private _deleteToken() { // to unsubscribe notifications push
        this.angularFireMessaging.getToken
            .pipe(mergeMap(token => this.angularFireMessaging.deleteToken(token)))
            .subscribe(
                (deleted) => {
                    this.ngZone.run(() => {
                        // we're back in the zone here
                        // console.log("Push subscription removed : ", deleted)
                        this._setNotifActive(false);
                        this._setNotifLoading(false);

                    });
                },
                (err) => {
                    this.ngZone.run(() => {
                        console.log("error", err);
                        this.popupService.showSnackBarTop(this.i18n.get('notificationNotSupported'));
                        this._setNotifActive(false);
                        this._setNotifLoading(false);
                    })
                }
            );
    }

    private _setNotifLoading(bool: boolean) {
        this.isNotificationProcessLoading = bool;
        this.isNotificationProcessLoading$.next(this.isNotificationProcessLoading);
    }

    private _setNotifActive(bool: boolean) {
        this.isNotificationActivated = bool;
        this.isNotificationActivated$.next(bool);
        bool ? localStorage.setItem('pushNotif', JSON.stringify(bool)) : localStorage.removeItem('pushNotif');
    }
}
