import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AudioPlayerService } from 'src/app/services/audioPlayer.service';
import { I18n } from 'src/app/services/i18n.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { SocialShareService } from 'src/app/services/socialShare.service';
import { UserService } from 'src/app/services/user.service';
import { Category, Music } from 'src/app/util/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.scss']
})
export class HomeViewComponent implements OnInit {
  categories: Category[];
  constructor(public socialShareService : SocialShareService, private router: Router, public userService: UserService, private masterDataService: MasterDataService, private audioPlayerService: AudioPlayerService, public i18n: I18n) { }

  ngOnInit(): void {
    this.categories = this.masterDataService.getCategories()
  }

  public getTopMusicCategory(): Category {
    return this.masterDataService.getCategoryById(this.getTopMusic()?.categoryId);
  }

  public getTopMusic(): Music {
    return this.masterDataService.getTopMusic();
  }

  public getTopMusicPictureUrl(): string {
    return this.masterDataService.getPictureUrlByMusic(this.getTopMusic())
  }

  public playTopMusique() {
    this.audioPlayerService.playIt(this.getTopMusic());
    this.audioPlayerService.isPlayerControlDisplayed = true;
  }

  public navTo(url) {
    this.router.navigate([url])
  }

  public getTopMusicRanking() {
    return this.masterDataService.getTopMusicRank()
  }
}
