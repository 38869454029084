<!-- <app-toolbar [title]="i18n.get('favoritesViewTitle')"></app-toolbar> -->
<mat-toolbar>
  <button mat-icon-button (click)="onClickReturn()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <span style="flex: 1 1 auto"></span>
  <span>{{i18n.get('favoritesViewTitle')}}</span>
  <span style="flex: 1 1 auto"></span>
  <span>
    <mat-menu #appMenu="matMenu">
      <button mat-menu-item (click)="clearPlayList()">
        <mat-icon>delete</mat-icon>
        <span>{{i18n.get('clearFavorites')}}</span>
      </button>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon class="white-icon svg mat-icon35">add</mat-icon>
    </button>
  </span>
</mat-toolbar>
<div>
  <mat-card>
    <mat-card-content>
      <p *ngIf="!playList?.length">
       {{i18n.get('noFavoris')}}
      </p>
      <mat-action-list *ngIf="playList?.length">
        <app-music-list-item *ngFor="let music of playList" [music]="music">
        </app-music-list-item>      
      </mat-action-list>
    </mat-card-content>
  </mat-card>
</div>
