import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderParams, PayPalOrder } from 'src/app/util/interfaces';
import { scrollDown } from 'src/app/util/utils';
import { environment } from 'src/environments/environment';
declare var paypal: any;

@Component({
  selector: 'app-paypal',
  templateUrl: './app-paypal.component.html',
  styleUrls: ['./app-paypal.component.scss']
})
export class AppPaypalComponent implements OnInit {
  @Input('orderParams') private _orderParams: OrderParams
  private _order: PayPalOrder;
  private payPalScriptSrc: string;
  public isLoading: boolean;

  constructor(private _router: Router) {
    this.payPalScriptSrc = environment.payPalScriptSrc + environment.payPalParamsSrc + environment.payPalClientId;

  }

  ngOnInit(): void {
    this._setLoader(true);
    this._initOrder();
    this._initPaypalScript();

  }
  private _setLoader(bool: boolean) {
    this.isLoading = bool;
  }

  private _initOrder() {
    this._order = {
      intent: "CAPTURE",
      purchase_units: [{
        amount: {
          currency_code: 'EUR',
          value: this._orderParams.price.toString() + '.00',
          breakdown: {
            item_total: {
              currency_code: 'EUR',
              value: this._orderParams.price.toString() + '.00',
            }
          }
        },
        items: [{
          name: this._orderParams.article,
          unit_amount: {
            value: this._orderParams.price.toString() + '.00',
            currency_code: 'EUR',
          },
          quantity: '1',
          description: this._orderParams.article + ' - ' + this._orderParams.category + ' - ' + 'Small Eyes',
          category: 'DIGITAL_GOODS'
        }]
      }]
    };
  }

  private _initPaypalScript() {
    var script = document.createElement('script');
    script.onload = this._onScriptLoaded.bind(this);
    script.src = this.payPalScriptSrc;
    // put the script balise into the head
    document.head.appendChild(script);
  }

  private _onScriptLoaded() {
    const order = this._order
    const router = this._router;

    paypal.Buttons({
      style: {
        layout: 'vertical',
        tagline : false,
        color: 'silver'
      },
      // This function sets up the details of the transaction, including the amount and line item details.
      createOrder: (data, actions) => actions.order.create(order),
      // This function captures the funds from the transaction.
      onApprove: (data, actions) => {
        return actions.order.capture().then(function (details) {
          // This function shows a transaction success message to the buyer.
          router.navigate([`/thanks/${details.id}`]);
        });
      }
    }).render('#paypal-button-container');

    this._setLoader(false);
    scrollDown()
  }

}
