<mat-list-item [ngClass]="{'app-accent': isActive()}" (click)="onClickListItem()">
  <div>
    <img class="avatar" [src]="pictureUrl">
  </div>
  <div (click)="onClickListItem()">
    <div class="mat-body-2" mat-line>{{getName()}}</div>
    <div mat-line class="subtitle"> {{getCategoryName()}}
    </div>
  </div>

  <span class="custo-spacer"></span>

  <button mat-icon-button [matMenuTriggerFor]="appMenu"  (click)="$event.stopPropagation()">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #appMenu="matMenu">
    <button mat-menu-item [routerLink]="'/music/' + music.id">
      <mat-icon>info</mat-icon>
      <span>{{i18n.get("seeDetails")}}</span>
    </button>
    <button mat-menu-item (click)="menuTrigger.closeMenu(); onCLickFavorite($event);">
      <mat-icon>{{getFavoriteIcon()}}</mat-icon>
      <span>{{getFavoriteText()}}</span>
    </button>
    <button [disabled]="!music.data.price" mat-menu-item (click)="order($event)">
      <mat-icon>add_shopping_cart</mat-icon>
      <span>{{i18n.get('buy')}}</span>
    </button>
  </mat-menu>

  <mat-divider></mat-divider>
</mat-list-item>