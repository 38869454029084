import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-to-confirm',
  templateUrl: './popup-to-confirm.component.html',
  styleUrls: ['./popup-to-confirm.component.scss']
})
export class PopupToConfirmComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
