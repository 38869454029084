<div class="app-mini-player miniPlayer">
    <ng-container *ngIf="!isPlayerView()" class="">
        <mat-grid-list cols="9" rowHeight="60px">
            <mat-grid-tile [colspan]="2" [rowspan]="1">
                <button class="app-accent" style="margin-left: 16px;" *ngIf="getDisplayButtonMiniPlayer()" mat-icon-button
                    (click)="onCollapse()">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
                <span class="custo-spacer"></span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="5" [rowspan]="1" (touchmove)="onCollapse()" (touchstart)="onCollapse()">
                <div>
                    <span id="playingMusicTitle">{{getMusicTitle()}}</span>
                </div>
            </mat-grid-tile>
            <mat-grid-tile class="app-accent" [colspan]="2" [rowspan]="1" matRipple [matRippleRadius]="30" [matRippleCentered]="true"
                [matRippleDisabled]="getDisabledControlButtons()" [matRippleUnbounded]="true">
                <div id="divMiniPlayButton">
                    <button *ngIf="getDisplayButtonMiniPlayer() && !loading" mat-icon-button [disableRipple]="true"
                        [disabled]="getDisabledControlButtons()" (click)="onClickPlay()" class="icon-button-medium">
                        <mat-icon [svgIcon]="getPlayIcon()" class="icon-button-large iconNotMaterial"></mat-icon>
                    </button>
                </div>
                <mat-spinner [diameter]="40" color="accent" *ngIf="getDisplayButtonMiniPlayer() && loading"></mat-spinner>
            </mat-grid-tile>
            <!-- <mat-grid-tile [colspan]="1" [rowspan]="1" matRipple [matRippleRadius]="30" [matRippleCentered]="true"
                [matRippleDisabled]="getDisabledControlButtons()" [matRippleUnbounded]="true">
                <div id="divMiniNextButton" class="text-align-center maxWidth100">
                    <button *ngIf="getDisplayButtonMiniPlayer()" mat-icon-button [disableRipple]="true"
                        [disabled]="getDisabledControlButtons()" (click)="onClickNext()" class="icon-button-small">
                            
                    </button>
                </div>

            </mat-grid-tile> -->
        </mat-grid-list>
    </ng-container>
